import React from 'react'

function ComingSoon() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>Coming soon</h1>
    </div>
  )
}

export default ComingSoon