import React, { useState,useEffect} from 'react';
import { Button, Form, Input, Select,Divider,List } from 'antd';
import { addDataToFirestore } from '../../services/firebase';
import collection from '../../constants/collection';
import { firestore } from '../../services/firebase';

function AddCourseForm() {
    const [form] = Form.useForm();
    const [courseData, setCourseData] = useState([]);


     // getting realtime data from firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await firestore.collection(collection.COURSES).get();
        const fetchedData = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setCourseData(fetchedData);
        const unsubscribe = firestore.collection(collection.COURSES).onSnapshot((snapshot) => {
          const updatedData = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          setCourseData(updatedData);
        });
        // The unsubscribe function will stop listening for updates when the component unmounts
        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


    const handleSubmit = async () => {
        try {
            // Validate the form
            const values = await form.validateFields();

            addDataToFirestore(collection.COURSES, values);
            form.resetFields();
        } catch (errorInfo) {
            console.error('Failed to submit:', errorInfo);
        }
    };

    return (
        <div>
        <Form
            layout='vertical'
            form={form}
            style={{
                maxWidth: 600,
            }}
        >
            <Form.Item
                label="Name of Course"
                name="name"
                rules={[{ required: true, message: 'Please enter the name' }]}
            >
                <Input placeholder="Name of course" />
            </Form.Item>

            <Form.Item
                label="Years"
                name="years"
                rules={[{ required: true, message: 'Please select Years' }]}
            >
                <Select>
                    <Select.Option key="1" value={1}>
                        1 Year
                    </Select.Option>
                    <Select.Option key="2" value={2}>
                        2 Years
                    </Select.Option>
                    <Select.Option key="3" value={3}>
                        3 Years
                    </Select.Option>
                    <Select.Option key="4" value={4}>
                        4 Years
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
        <Divider orientation="left">Courses</Divider>
        <List
      size="small"
      bordered
      dataSource={courseData.map(e => `${e.name} | ${e.years} Years`)}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
        </div>
    );
}

export default AddCourseForm