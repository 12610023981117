import React, { createContext, useContext, useState, useEffect } from 'react';

const AllLanguageContext = createContext();

export const AllLanguageProvider = ({ children }) => {
  const [allLanguage, setAllLanguage] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedAcademic = localStorage.getItem('AllLanguage');
    return savedAcademic ? JSON.parse(savedAcademic) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allStudents changes
    localStorage.setItem('AllLanguage', JSON.stringify(allLanguage));
  }, [allLanguage]);

  return (
    <AllLanguageContext.Provider value={{ allLanguage, setAllLanguage }}>
      {children}
    </AllLanguageContext.Provider>
  );
};
export const useAllLanguage = () => {
    return useContext(AllLanguageContext);
  };
  