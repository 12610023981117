// Corrected version of staffContext.js

import { createContext, useContext, useState, useEffect } from 'react';

export const CurrentStaffContext = createContext();

export const useCurrentStaff = () => useContext(CurrentStaffContext);

export const CurrentStaffProvider = ({ children }) => {
  const [currentStaff, setCurrentStaff] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('currentStaff'); 
    if (storedData) {
      setCurrentStaff(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (currentStaff !== null) { 
      localStorage.setItem('currentStaff', JSON.stringify(currentStaff)); 
    }
  }, [currentStaff]); 

  const setStaffData = (data) => {
    setCurrentStaff(data);
  };

  return (
    <CurrentStaffContext.Provider value={{ currentStaff, setStaffData }}> 
      {children}
    </CurrentStaffContext.Provider>
  );
};
