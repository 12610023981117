
import logo from '../../assets/images/logo.png';
import React from 'react';
import { Layout, Menu, Button, Popconfirm } from 'antd';
import { NavLink } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import {adminRoutes,studentRoutes,institutionHeadRoutes} from '../../routes/route_config';
import { firebaseAuth } from '../../services/firebase';
import { useUserData } from '../../context/userDataContext';
import { getRoutesByRole } from '../../routes/user_routes';
import { useCurrentInstitution } from '../../context/currentInstitutionContext';
import userTypes from '../../constants/userTypes';


const { Sider } = Layout;

function AppSider({ collapsed }) {
  const { userData } = useUserData();
  const {currentInstitution} = useCurrentInstitution();
  const routesToRender = userData ? (userData.role === userTypes.INSTITUTION_HEAD ? getRoutesByRole(userData.role, currentInstitution.features) : getRoutesByRole(userData.role)) : [];

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <Menu mode="inline">
        {routesToRender
          .filter(route => route.show_sider)
          .map((route, index) => (
            <Menu.Item key={route.path} icon={<route.icon />}>
              <NavLink to={route.path}>{route.title}</NavLink>
            </Menu.Item>
          ))}
      </Menu>
      <div style={{ padding: '16px', borderTop: '1px solid #f0f0f0' }}>

        <Popconfirm
          placement="right"
          title='logout?'
          description="Are you sure?"
          icon={
            <LogoutOutlined
              style={{
                color: 'red',
              }}
            />
          }
          okText="Yes"
          cancelText="No"
          onConfirm={() => firebaseAuth.signOut()}
        >
          <Button
            key="logout"
            icon={<LogoutOutlined />}
            style={{ color: 'red', width: '100%' }}>
          {collapsed?"":"Logout"}
          </Button>
        </Popconfirm>


      </div>
    </Sider>
  );

}

export default AppSider;
