import userTypes from "../constants/userTypes";
import { adminRoutes, institutionHeadRoutes, studentRoutes, managerRoutes, staffRoutes } from "./route_config";

export const getRoutesByRole = (role, featureList = []) => {
  console.log(featureList);
  switch (role) {
    case userTypes.MANAGER:
      return managerRoutes;
    case userTypes.ADMIN:
      return adminRoutes;
    case userTypes.INSTITUTION_HEAD:
      return institutionHeadRoutes.filter((route) => featureList.includes(route.id));
    case userTypes.STUDENT:
      return studentRoutes;
    case userTypes.STAFF:
      return staffRoutes;
    default:
      return [];
  }
};
