
import collection from "../../../constants/collection";
import { firestore } from "../../../services/firebase";
import { formatDOB } from '../../../utils/formatDOB';
import { errorMessage } from "../../../utils/messages";



export const fetchAdmissionDetails = ({ studentId, setData }) => {
    try {
        const query = firestore.collection(collection.STUDENTS_COLLECTION).doc(studentId);

        // Get data from the specific document
        return query.onSnapshot((doc) => {
            if (doc.exists) {
                // Document exists, extract its data
                const fetchedData = {
                    key: doc.id,
                    ...doc.data(),
                };
                setData(fetchedData); // Update state with fetched data
            } else {
                // Document doesn't exist
                errorMessage('Data not Updated')
            }
        }, (error) => {
            console.error("Error getting document:", error);
        });
    } catch (error) {
        console.error('Error fetching basic details:', error);
    }
};


export const formatAdmissionData = (item) => {

   
        const formattedItem = {
            "Instituion": item.name,
            "Address": item.address,
            "Place": item.place,
            "Local Body": item.local_body,
            "District": item.district,
            "State": item.state,
            "Pincode": item.pincode,
            "Nationality": item.nationality,
            "Phone Number": item.phone,
            "Date of Birth": formatDOB(item.dob),
            

        };


        return formattedItem;
    
};
