import React, { useState, useEffect } from 'react';
import { Card, Button, Tooltip } from 'antd';
import { EyeTwoTone } from '@ant-design/icons';
import AppTable from '../../components/app_table';
import { useNavigate } from 'react-router-dom';
import { useInstitution } from '../../context/InstitutionContext';
import { useUserData } from '../../context/userDataContext';

function InstitutionForAttendance() {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { institutionList } = useInstitution();
  const { userData } = useUserData();

  const handleViewClick = (record) => {
    const institutionId = userData.role === 'institution_head' ? userData.institution : record.id;
    navigate(`/attendance`, { state: { instId: institutionId } });
  };

  useEffect(() => {
    setTableData(institutionList);
  }, [institutionList]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <Tooltip title="View">
          <EyeTwoTone onClick={() => handleViewClick(record)} style={{ cursor: 'pointer' }} />
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Card title="Institutions">
        <AppTable columns={columns} data={tableData} pagination={false} />
      </Card>
    </div>
  );
}

export default InstitutionForAttendance;