import React, { useEffect } from 'react';
import { Button, Form, Input, DatePicker, message } from 'antd';
import moment from 'moment';
import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

function UpdateBatchForm({ record, onClose }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        start_year: moment(record.start_year, 'YYYY'), // Ensure start_year is correctly initialized
      });
    }
  }, [record, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const batchData = {
        name: values.name,
        start_year: values.start_year.year(),
      };
      await firestore.collection(collection.BATCHES_COLLECTION).doc(record.key).update(batchData);
      message.success('Batch updated successfully');
      onClose(); // Close the drawer after successful submission
    } catch (error) {
      console.error('Error updating batch:', error);
      message.error('Failed to update batch');
    }
  };

  return (
    <Form
      layout='vertical'
      form={form}
      style={{ maxWidth: 600 }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input placeholder="Name of Batch" />
      </Form.Item>

      <Form.Item
        label="Start Year"
        name="start_year"
        rules={[{ required: true, message: 'Please select a year' }]}
      >
        <DatePicker picker='year' />
      </Form.Item>

      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default UpdateBatchForm;
