import React, { useState } from 'react';
import { Button, Drawer } from 'antd'
import AddEducationForm from './AddEducationForm';

function AddEducation() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button onClick={openDrawer} type='primary' style={{ marginBottom: 10 }}>Add</Button>
      <Drawer title='Add Course' width={500} onClose={closeDrawer} open={isDrawerOpen}>
        <AddEducationForm />
      </Drawer>
    </div>
  )
}

export default AddEducation