import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { fetchSkillDetails } from './store';
import AddSkill from './AddSkill';
import { skills } from './store';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function Skill() {
    const { currentStudent } = useCurrentStudent();
    const [skillData, setSkillData] = useState([]);
    const {userData} = useUserData();


    useEffect(() => {
        const unsubscribe = fetchSkillDetails({
            studentId: currentStudent.userId,
            setData: setSkillData
        });

        // Unsubscribe from real-time updates when the component unmounts
        return () => {
            unsubscribe();
        };
    }, [currentStudent.userId]);

    // Initialize an object to store arrays by type
    const categorizedSkills = skills.reduce((acc, skill) => {
        const header = skill.type.replace(/_/g, ' '); // Convert snake_case to title case
        if (!acc[header]) {
            acc[header] = [];
        }
        return acc;
    }, {});

    // Populate categorizedSkills with actual skill names
    skillData.forEach(item => {
        item.skills.forEach(skillName => {
            const type = skills.find(skill => skill.name === skillName)?.type;
            const header = type ? type.replace(/_/g, ' ') : ''; // Convert snake_case to title case
            if (header && categorizedSkills[header]) {
                categorizedSkills[header].push(skillName);
            }
        });
    });

    return (
        <div>
         {userData.role !== userTypes.STUDENT ?   <AddSkill skills={skillData.length !== 0? skillData[0].skills:null}/>:null}
            {/* Render lists for each type */}
            {Object.keys(categorizedSkills).map(header => (
                <div key={header}>
                    <h3>{header}</h3>
                    <List
                        bordered
                        dataSource={categorizedSkills[header]}
                        renderItem={skill => (
                            <List.Item>
                                {skill}
                            </List.Item>
                        )}
                    />
                </div>
            ))}
        </div>
    );
}

export default Skill;
