import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button, Drawer, Row, Col, Statistic, Tooltip } from 'antd';
import { EditTwoTone, DoubleRightOutlined } from '@ant-design/icons';
import { firestore } from '../../services/firebase';
import useFetchInstitutions from '../../utils/getInstitutions';
import AppTable from '../../components/app_table';
import AddBatchForm from './AddBatchForm';
import collection from '../../constants/collection';
import { useUserData } from '../../context/userDataContext';
import { useAllStudents } from '../../context/allStudentContext';
import UpdateBatchForm from './UpdateBatchForm';

function Batches() {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false);
  const [totalStudentCount, setTotalStudentCount] = useState(0);
  const [verifiedStudentCount, setVerifiedStudentCount] = useState(0);
  const [familyVerifiedStudentCount, setFamilyVerifiedStudentCount] = useState(0);
  const [notVerifiedStudentCount, setNotVerifiedStudentCount] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const navigate = useNavigate();
  const { userData } = useUserData();
  const { instId } = location.state || {};
  const { institutionList } = useFetchInstitutions();
  const { allStudents } = useAllStudents();

  const myInstId = userData.role === 'institution_head' ? userData.institution : instId;
  const myInst = institutionList?.find((e) => e.key === myInstId);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleViewClick = (record) => {
    navigate('/students', { state: { batch: record } });
  };

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setEditDrawerOpen(true);
  };

  const closeEditDrawer = () => {
    setEditDrawerOpen(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Year',
      dataIndex: 'start_year',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <span>
          {/* <Tooltip title="Edit">
            <EditTwoTone onClick={() => handleEditClick(record)} style={{ color: 'blue', cursor: 'pointer' }} />
          </Tooltip> */}
          <Tooltip title="View details">
            <DoubleRightOutlined onClick={() => handleViewClick(record)} style={{ color: 'blue', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (myInst) {
      const fetchData = async () => {
        try {
          const totalStudents = allStudents.filter((e) => e.institution === myInst.key);
          setTotalStudentCount(totalStudents.length);
          const verifiedStudents = totalStudents.filter((e) => e.adhaar !== undefined && e.status !== undefined);
          setVerifiedStudentCount(verifiedStudents.length);
          const familyVerifiedStudents = totalStudents.filter((e) => e.adhaar !== undefined && e.status !== undefined && e.father !== undefined);
          setFamilyVerifiedStudentCount(familyVerifiedStudents.length);
          const notVerifiedStudents = totalStudents.filter((e) => e.adhaar === undefined || e.status === undefined);
          setNotVerifiedStudentCount(notVerifiedStudents.length);

          const querySnapshot = await firestore.collection(collection.BATCHES_COLLECTION)
            .where('institution', '==', myInst.key)
            .get();
          const fetchedData = querySnapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          setTableData(fetchedData);
          const unsubscribe = firestore.collection(collection.BATCHES_COLLECTION)
            .where('institution', '==', myInst.key)
            .onSnapshot((snapshot) => {
              const updatedData = snapshot.docs.map((doc) => ({
                key: doc.id,
                ...doc.data(),
              }));
              setTableData(updatedData);
            });
          return () => unsubscribe();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [myInst, allStudents]);

  tableData.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      {myInst ? (
        <>
          <h2>{myInst.name}</h2>
          <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false}>
                <Statistic
                  title="Batches"
                  value={tableData.length}
                  valueStyle={{ color: '#9307f0' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false}>
                <Statistic
                  title="Students"
                  value={totalStudentCount}
                  valueStyle={{ color: '#0741f0' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false}>
                <Statistic
                  title="Verified Students"
                  value={verifiedStudentCount}
                  valueStyle={{ color: '#bfa41d' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false}>
                <Statistic
                  title="Fam.Details Updated "
                  value={familyVerifiedStudentCount}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false}>
                <Statistic
                  title="Not Verified"
                  value={notVerifiedStudentCount}
                  valueStyle={{ color: '#cf1322' }}
                />
              </Card>
            </Col>
          </Row>
          <Card
            title="Batches"
            extra={
              userData.role === 'institution_head' ? (
                <Button type="primary" onClick={openDrawer}>
                  Add Batch
                </Button>
              ) : null
            }
          >
            <AppTable columns={columns} data={tableData} pagination={false} />
          </Card>
          <Drawer title="Add Batch" width={500} onClose={closeDrawer} open={isDrawerOpen}>
            <AddBatchForm />
          </Drawer>
          <Drawer title="Edit Batch" width={500} onClose={closeEditDrawer} open={isEditDrawerOpen}>
            <UpdateBatchForm record={selectedRecord} onClose={closeEditDrawer} />
          </Drawer>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Batches;