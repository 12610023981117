import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Button, Popconfirm } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { formatLanguageData, fetchLanguageDetails, deleteLanguageDetails } from './store';
import AddLanguage from './AddLanguage';
import { firestore } from '../../../services/firebase';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function LanguageDetails() {
  const { currentStudent } = useCurrentStudent();
  const [languageData, setLanguageData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const {userData} = useUserData();


  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchLanguageDetails({
      studentId: currentStudent.userId,
      setData: setLanguageData
    });

    // Unsubscribe from real-time updates when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentStudent.userId]);

  const formattedData = formatLanguageData(languageData);

  const handleDelete = (id) => {
    deleteLanguageDetails({ id });
  };

  return (
    <div>
       {userData.role !== userTypes.STUDENT ?<AddLanguage />:null}
      {formattedData.map((item) => (
        <div key={item.key} style={{ marginBottom: 10 }}>
          <Card
            type='inner'
            title={item.Language}
            extra={<Popconfirm
              placement="left"
              title='remove?'
              description="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(item.key)}
            ><Button danger >Delete</Button></Popconfirm>}
            style={{ marginLeft: 10 }}
          >
            <Descriptions layout='horizontal'>
              {Object.keys(item).filter(key => key !== 'key').map((key) => (
                <Descriptions.Item key={key} label={key}>{item[key]}</Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default LanguageDetails;
