import React, { useState, useEffect } from 'react';
import { Form, Button, Divider, Tag } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { skills } from './store'; // Ensure this import is correct based on your file structure

function AddSkillForm({ skillsAlreadyHave }) {
    const [form] = Form.useForm();
    const { currentStudent } = useCurrentStudent();
    const [selectedSkills, setSelectedSkills] = useState([]);

    useEffect(() => {
        // Initialize selectedSkills with skillsAlreadyHave when component mounts
        if (Array.isArray(skillsAlreadyHave)) {
            setSelectedSkills([...skillsAlreadyHave]);
        }
    }, [skillsAlreadyHave]);

    const handleSkillClick = (skill) => {
        setSelectedSkills((prevSkills) => {
            if (!prevSkills.includes(skill)) {
                return [...prevSkills, skill];
            }
            return prevSkills;
        });
    };

    const handleRemoveSkill = (skill) => {
        setSelectedSkills((prevSkills) => prevSkills.filter((s) => s !== skill));
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            values.student_id = currentStudent.userId;
            values.skills = selectedSkills; // Add selected skills to form values
            await firestore.collection(collection.SKILL_COLLECTION).doc(currentStudent.userId).set(values);
            form.resetFields();
            successMessage('Successfully Added');
        } catch (errorInfo) {
            errorMessage("Failed");
        }
    };

    const renderSkills = (type) => {
        return skills
            .filter(skill => skill.type === type)
            .map(skill => (
                <Tag key={skill.name} onClick={() => handleSkillClick(skill.name)}>
                    {skill.name}
                </Tag>
            ));
    };

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                style={{ maxWidth: 600 }}
                initialValues={{}}
            >
                <Divider orientation="left">Selected Skills</Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    {selectedSkills.map(skill => (
                        <Tag key={skill} closable onClose={() => handleRemoveSkill(skill)}>
                            {skill}
                        </Tag>
                    ))}
                </div>

                <Divider orientation="left">Soft Skills</Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    {skills && renderSkills('soft_skill')}
                </div>

                <Divider orientation="left">Technical Skills</Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    {skills && renderSkills('technical_skill')}
                </div>

                <Divider orientation="left">Creative Skills</Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    {skills && renderSkills('creative_skill')}
                </div>

                <Divider orientation="left">Islamic&Kithab Skills</Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    {skills && renderSkills('islamic_and_kithab')}
                </div>

                <Form.Item>
                    <Button type="primary" onClick={handleSubmit} style={{ marginTop: 10 }}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AddSkillForm;
