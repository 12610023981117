
import collection from "../../../constants/collection";
import { firestore } from "../../../services/firebase";
import { errorMessage, successMessage } from "../../../utils/messages";


export const fetchAchivementDetails = ({ studentId, setData }) => {
  try {
    const query = firestore.collection(collection.ACHIEVEMENTS_COLLECTION).where('student_id', '==', studentId);

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching academic details:', error);
  }
};
export const deleteAchivementDetails = ({ id }) => {
  try {
   firestore.collection(collection.ACHIEVEMENTS_COLLECTION).doc(id).delete();

    successMessage('Item removed')
  } catch (error) {
    errorMessage("Failed to remove item")
    console.error('Error fetching academic details:', error);
  }
};


export const formatAchivementsData = (data) => {

  return data.map(item => {
    var currentEvent = eventList.find((e)=>e.id ===item.event);
    const formattedItem = {
      "key":item.id,
      "Topic":item.topic,
      "Level":item.level,
    };
    if (item.event !==0) {
      formattedItem["Event"] = currentEvent.name;
    }else{
      formattedItem["Event"] =item.other_event;
    }
    if(item.link){
      formattedItem["Link"] = item.link;
    }
    if(item.description){
      formattedItem["Description"] = item.description;
    }
    if(item.rank){
      formattedItem["rank"] = item.rank;
    }
   

    return formattedItem;
  });
};

export const levelList = 
  [
    { "id": 1, "name": "District Level" }, 
    { "id": 2, "name": "State Level" }, 
    { "id": 3, "name": "National Level" }, 
    { "id": 4, "name": "International Level" }, 
    { "id": 0, "name": "Other" }, 
  ]

  export const eventList = 
  [
    { "id": 1, "name": "Sahithyotsav" }, 
    { "id": 2, "name": "Thartheel" }, 
    { "id": 0, "name": "Other" }, 
  ]

  export const rankOptions = [
    { key: 'first', id: 'First', name: 'First Rank' },
    { key: 'second', id: 'Second', name: 'Second Rank' },
    { key: 'third', id: 'Third', name: 'Third Rank' },
    { key: 'participation', id: 'Participation', name: 'Participation' },   
    { key: 'champion', id: 'Individual Champion', name: 'Individual Champion' },

];