import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAllStudents } from '../../../context/allStudentContext';
import { useInstitution } from '../../../context/InstitutionContext';
import { firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { Card, List, Typography, Row, Col, Tabs, Statistic, message, Alert } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './AttendanceDetails.css'; // Import the CSS file

const { Title, Text } = Typography;
const { TabPane } = Tabs;

function AttendanceDetails() {
  const location = useLocation();
  const { record } = location.state || {};
  const { allStudents } = useAllStudents();
  const { institutionList } = useInstitution();
  const [absentees, setAbsentees] = useState([]);
  const [presentStudents, setPresentStudents] = useState([]);
  const [absentStudents, setAbsentStudents] = useState([]);
  const [batchNames, setBatchNames] = useState([]);

  useEffect(() => {
    if (record) {
      // Fetch batch names
      const batchPromises = record.batches.map(batchId =>
        firestore.collection(collection.BATCHES_COLLECTION)
          .doc(batchId)
          .get()
      );

      Promise.all(batchPromises).then(batchDocs => {
        const names = batchDocs.map(doc => doc.exists ? doc.data().name : '');
        setBatchNames(names);
      });

      // Fetch absentees from Firestore
      firestore.collection(collection.ABSENTEES_COLLECTION)
        .where('attendanceId', '==', record.key)
        .get()
        .then((querySnapshot) => {
          const absenteesList = querySnapshot.docs.map(doc => doc.data().studentId);
          setAbsentees(absenteesList);
        });
    }
  }, [record]);

  useEffect(() => {
    if (record) {
      const studentsInBatches = allStudents.filter(student => record.batches.includes(student.batch));
      const present = studentsInBatches.filter(student => !absentees.includes(student.id));
      const absent = studentsInBatches.filter(student => absentees.includes(student.id));
      setPresentStudents(present);
      setAbsentStudents(absent);
    }
  }, [record, allStudents, absentees]);

  const handleAttendanceChange = async (studentId, checked) => {
    if (checked) {
      setAbsentees(prev => prev.filter(id => id !== studentId));
      setPresentStudents(prev => [...prev, allStudents.find(student => student.id === studentId)]);
      setAbsentStudents(prev => prev.filter(student => student.id !== studentId));

      // Update Firestore
      const absenteesRef = firestore.collection(collection.ABSENTEES_COLLECTION);
      const querySnapshot = await absenteesRef.where('attendanceId', '==', record.key).where('studentId', '==', studentId).get();
      querySnapshot.forEach(doc => doc.ref.delete());
      message.success('Student marked as present');
    } else {
      setAbsentees(prev => [...prev, studentId]);
      setPresentStudents(prev => prev.filter(student => student.id !== studentId));
      setAbsentStudents(prev => [...prev, allStudents.find(student => student.id === studentId)]);

      // Update Firestore
      await firestore.collection(collection.ABSENTEES_COLLECTION).add({ attendanceId: record.key, studentId });
      message.success('Student marked as absent');
    }
  };

  if (!record) {
    return <p>No attendance record found.</p>;
  }

  const institutionName = institutionList.find(inst => inst.id === record.institution)?.name || 'Unknown Institution';

  return (
    <Card title="Attendance Details">
      <Alert message="This feature is under development" type="warning" showIcon style={{ marginBottom: 16 }} />
      <Row gutter={16} style={{ marginBottom: 16 }} xs={24} sm={6} md={6} lg={6}>
        <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
          <Card bordered={false}>
            <Statistic
              title="Total Batches"
              value={record.batches.length}
              valueStyle={{ color: '#9307f0' }}
            />
          </Card>
        </Col>

        <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
          <Card bordered={false}>
            <Statistic
              title="Total Students"
              value={presentStudents.length + absentStudents.length}
              valueStyle={{ color: '#0741f0' }}
            />
          </Card>
        </Col>

        <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
          <Card bordered={false}>
            <Statistic
              title="Present Students"
              value={presentStudents.length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

        <Col xs={24} sm={6} md={6} style={{ marginBottom: 16 }}>
          <Card bordered={false}>
            <Statistic
              title="Absent Students"
              value={absentStudents.length}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Title level={4}>Name:</Title>
          <Text>{record.name}</Text>
        </Col>
        <Col span={12}>
          <Title level={4}>Date and Time:</Title>
          <Text>{record.date_time}</Text>
        </Col>
        <Col span={12}>
          <Title level={4}>Institution:</Title>
          <Text>{institutionName}</Text>
        </Col>
        <Col span={12}>
          <Title level={4}>Batches:</Title>
          <Text>{batchNames.join(', ')}</Text>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Present Students" key="1">
          <List
            dataSource={presentStudents}
            renderItem={student => (
              <List.Item className="list-item">
                <Text className="student-name">{student.name}</Text>
                <MinusCircleOutlined
                  className="attendance-icon"
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => handleAttendanceChange(student.id, false)}
                />
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="Absent Students" key="2">
          <List
            dataSource={absentStudents}
            renderItem={student => (
              <List.Item className="list-item">
                <Text className="student-name">{student.name}</Text>
                <PlusCircleOutlined
                  className="attendance-icon"
                  style={{ color: 'green', cursor: 'pointer' }}
                  onClick={() => handleAttendanceChange(student.id, true)}
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
}

export default AttendanceDetails;