
import collection from "../../../constants/collection";
import { firestore } from "../../../services/firebase";
import { errorMessage, successMessage } from "../../../utils/messages";


export const fetchSkillDetails = ({ studentId, setData }) => {
  try {
    const query = firestore.collection(collection.SKILL_COLLECTION).where('student_id', '==', studentId);

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching Skill details:', error);
  }
};
export const deleteSkillDetails = ({ id }) => {
  try {
   firestore.collection(collection.SKILL_COLLECTION).doc(id).delete();

    successMessage('Language removed')
  } catch (error) {
    errorMessage("Failed to remove Language")
    console.error('Error fetching Skill details:', error);
  }
};


export const formatSkillData = (data) => {

  return data.map(item => {
    const formattedItem = {
      "key":item.id,
      "Type":item.type,
      "Title":item.title,
      "Description":item.description,

    };
   

    return formattedItem;
  });
};

export const skills = [
    { type: 'soft_skill', name: 'Communication' },
    { type: 'soft_skill', name: 'Time Management' },
    { type: 'soft_skill', name: 'Decision Making' },
    { type: 'soft_skill', name: 'Team Work' },
    { type: 'soft_skill', name: 'Leadership Capacity' },
    { type: 'soft_skill', name: 'Problem Solving' },
    { type: 'soft_skill', name: 'Public Speaking' },
    { type: 'soft_skill', name: 'Motivation Speaking' },
    { type: 'soft_skill', name: 'Customer Service' },
    { type: 'soft_skill', name: 'Event Management' },
    
    { type: 'technical_skill', name: 'Computer Programming' },
    { type: 'technical_skill', name: 'Software Development' },
    { type: 'technical_skill', name: 'Computer Hardware Repairing' },
    { type: 'technical_skill', name: 'Social Media Influence' },
    { type: 'technical_skill', name: 'Networking' },
    { type: 'technical_skill', name: 'Digital Marketing' },
    { type: 'technical_skill', name: 'Video Editing' },
    { type: 'technical_skill', name: 'Web Designing' },
    { type: 'technical_skill', name: 'MS Office Expert' },
    { type: 'technical_skill', name: 'IT Project Management' },
    { type: 'technical_skill', name: 'Arabic Typing' },
    { type: 'technical_skill', name: 'English Typing' },
    { type: 'technical_skill', name: 'Malayalam Typing' },
    { type: 'technical_skill', name: 'Urudu Typing' },



    { type: 'creative_skill', name: 'Calligraphy' },
    { type: 'creative_skill', name: 'Drawing' },
    { type: 'creative_skill', name: 'Creative Writing' },
    { type: 'creative_skill', name: 'Research' },
    { type: 'creative_skill', name: 'Singing' },
    { type: 'creative_skill', name: 'Content Writing' },
    { type: 'creative_skill', name: 'Graphic Design' },
    { type: 'creative_skill', name: 'Music Composition' },
    { type: 'creative_skill', name: 'Interior Design' },
    { type: 'creative_skill', name: 'Photography' },

  
      { type: 'islamic_and_kithab', name: "Quira'ath" },
      { type: 'islamic_and_kithab', name: "Imamath" },
      { type: 'islamic_and_kithab', name: "Majlis Leadership" },
      { type: 'islamic_and_kithab', name: "Hadees Knowledge" },
      { type: 'islamic_and_kithab', name: "Fiqh" },
      { type: 'islamic_and_kithab', name: "Thareekh & Manaqib" },
      { type: 'islamic_and_kithab', name: "Nahvu & Swarf" },
      { type: 'islamic_and_kithab', name: "Ideal" },
      { type: 'islamic_and_kithab', name: "Thasneef" },
      { type: 'islamic_and_kithab', name: "Muthalaa" },
      { type: 'islamic_and_kithab', name: "Munalara" },
      { type: 'islamic_and_kithab', name: "Shamila" },
      { type: 'islamic_and_kithab', name: "Ilmul Falak" },
      { type: 'islamic_and_kithab', name: "Ilmul Faraay'il" },
      { type: 'islamic_and_kithab', name: "Islamic Content Writing" }
    
    ];
