import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Button, Drawer } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { fetchFamilyDetails, formatFamilyData } from './store';
import UpdateFamilyDetails from './UpadateFamilyDetails';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function FamilyDetails() {
  const { currentStudent } = useCurrentStudent();
  const [familyData, setFamilyData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { userData } = useUserData();


  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    
      const unsubscribe = fetchFamilyDetails({
        studentId: currentStudent.userId,
        setData: setFamilyData
      });

      return () => {
          unsubscribe();
      };
    
  }, [currentStudent.userId]);
  const formattedData = formatFamilyData(familyData);


  return (
    <div>
      <Card
        type='inner'
        title="Family Info"
        extra={userData.role !== userTypes.STUDENT ? <Button onClick={openDrawer}>Update</Button> : null}
        style={{ marginLeft: 10 }}
      >
        <Drawer title='Update Family Info' width={500} onClose={closeDrawer} open={isDrawerOpen}>
          <UpdateFamilyDetails currentStudent={currentStudent}/>
        </Drawer>
        <Descriptions layout="horizontal">
              {Object.entries(formattedData).map(([title, content]) => (
                <Descriptions.Item label={title}>{content}</Descriptions.Item>
              ))}
            </Descriptions>
      </Card>
    </div>
  );
}

export default FamilyDetails;
