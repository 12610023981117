// services/firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';



const firebaseConfig = {
  apiKey: "AIzaSyCo5G1e9DuIvo6hkfM_7vfJ6qW0kTXMco8",
  authDomain: "madin-erp.firebaseapp.com",
  projectId: "madin-erp",
  storageBucket: "madin-erp.appspot.com",
  messagingSenderId: "472233338589",
  appId: "1:472233338589:web:bbb7d800979021aac5dd34",
  measurementId: "G-NQD42HZ4H4"
};

const app = firebase.initializeApp(firebaseConfig);

const firestore = app.firestore()

const firebaseAuth = app.auth();

/////////////
const addDataToFirestore = async (collectionName, data) => {
  try {
    // Get a reference to the document using the specified ID
    const docRef = firestore.collection(collectionName).doc();

    // Add data to the document with timestamps
    await docRef.set({
      ...data,
      id: docRef.id, // Add the ID to the data
      created_at: firebase.firestore.FieldValue.serverTimestamp(), // Add created_at timestamp
      updated_at: firebase.firestore.FieldValue.serverTimestamp()  // Add updated_at timestamp
    });

    // Return the document reference
    return docRef;
  } catch (error) {
    console.error("Error adding document to Firestore: ", error);
    throw error;
  }
};
/////////////

export { app, firestore, addDataToFirestore,firebaseAuth };