import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Button, Popconfirm } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { formatSulukData, fetchSulukDetails, deleteSulukDetails } from './store';
import AddSuluk from './AddSuluk';
import { useUserData } from '../../../context/userDataContext';
import userTypes from '../../../constants/userTypes';

function Suluk() {
    const { userData } = useUserData();
    const { currentStudent } = useCurrentStudent();
    const [languageData, setLanguageData] = useState([]);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const openDrawer = () => {
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    useEffect(() => {
        const unsubscribe = fetchSulukDetails({
            studentId: currentStudent.userId,
            setData: setLanguageData
        });



        // Unsubscribe from real-time updates when the component unmounts
        return () => {
            unsubscribe();
        };
    }, [currentStudent.userId]);

    const formattedData = formatSulukData(languageData);

    const handleDelete = (id) => {
        deleteSulukDetails({ id });
    };

    const hasLink = (text) => {
        // Regular expression to check for a link (http:// or https://)
        const linkRegex = /(http:\/\/|https:\/\/)/;
        return linkRegex.test(text);
    };

    return (
        <div>
            {userData.role !== userTypes.STUDENT ? <AddSuluk /> : null}
            {formattedData.map((item) => (
                <div key={item.key} style={{ marginBottom: 10 }}>
                    <Card
                        type='inner'
                        title={item.Title}
                        headStyle={item.Type === "Negative" ? { color: 'red' } : { color: 'green' }}
                        extra={
                            userData.role !== userTypes.STUDENT ? (
                                <Popconfirm
                                    placement="left"
                                    title='Remove?'
                                    description="Are you sure?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => handleDelete(item.key)}
                                >
                                    <Button danger>Delete</Button>
                                </Popconfirm>
                            ) : null
                        }
                        style={{ marginLeft: 10 }}
                    >
                        <Descriptions layout='horizontal'>
                            {Object.keys(item)
                                .filter(key => key !== 'key' && key !== 'Title' && key !== 'Type')
                                .map((key) => (
                                    <Descriptions.Item key={key} label={key}>
                                        
                                        {hasLink(item[key]) ? (
                                            <a href={item[key]} target="_blank" rel="noopener noreferrer">
                                                View
                                            </a>
                                        ):item[key]}
                                    </Descriptions.Item>
                                ))}
                        </Descriptions>
                    </Card>
                </div>
            ))}
        </div>
    );
}

export default Suluk;
