import React, { useEffect } from 'react';
import { useAllStudents } from '../../context/allStudentContext';
import { useInstitution } from '../../context/InstitutionContext';
import { Row, Col, Card, Statistic, Progress, Tooltip } from 'antd';
import { useUserData } from '../../context/userDataContext';
import { fetchAcademics, fetchAdminInstituions, fetchLanguages, fetchStudents } from './store';
import { useAllAcademic } from '../../context/allAcademicContext';

function AdminDashBoard() {
  const { allStudents, setAllStudents } = useAllStudents();
  const { institutionList, setInstitutionList } = useInstitution();
  const { allAcademic, setAllAcademic } = useAllAcademic();
  const { userData } = useUserData();

  useEffect(() => {
    if (userData.role) {
      fetchStudents({ setData: setAllStudents });
      fetchAdminInstituions({ setData: setInstitutionList });
      fetchAcademics({ setData: setAllAcademic });
    }
  }, [userData, setAllStudents, setInstitutionList, setAllAcademic]);

  const verifiedStudents = allStudents.filter((e) => e.adhaar !== undefined && e.status !== undefined);
  const notVerifiedStudents = allStudents.filter((e) => e.adhaar === undefined || e.status === undefined);
  const familyVerifiedStudents = allStudents.filter((e) => e.adhaar !== undefined && e.status !== undefined && e.father !== undefined);
  const imageUpdated = allStudents.filter((e) => e.imagePath !== undefined);

  const verifiedStudentsPercentage = allStudents.length > 0
    ? ((verifiedStudents.length / allStudents.length) * 100).toFixed(1)
    : 0;
  const familyVerifiedStudentsPercentage = allStudents.length > 0
    ? ((familyVerifiedStudents.length / allStudents.length) * 100).toFixed(1)
    : 0;

  const imageUpdatedPercentage = allStudents.length > 0
    ? ((imageUpdated.length / allStudents.length) * 100).toFixed(1)
    : 0;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Institutions"
              value={institutionList.length}
              valueStyle={{ color: '#9307f0' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Students"
              value={allStudents.length}
              valueStyle={{ color: '#0741f0' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Verified Students"
              value={verifiedStudents.length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Not Verified"
              value={notVerifiedStudents.length}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col xs={24} sm={16} md={16}>
          <Card title="Family Info Updation">
            {institutionList.map((institution) => {
              const totalStudents = institution.students_count ?? 100;
              const verifiedStudents = allStudents.filter(
                (student) => student.institution === institution.id && student.adhaar !== undefined && student.status !== undefined && student.father !== undefined
              ).length;
              const notVerifiedStudents = allStudents.filter(
                (student) => student.institution === institution.id && (student.adhaar === undefined || student.status === undefined)
              ).length;
              const verifiedStudentsPercentage = totalStudents === 0 ? 0 : ((verifiedStudents / totalStudents) * 100).toFixed(1);
              const notVerifiedStudentsPercentage = totalStudents === 0 ? 0 : ((notVerifiedStudents / totalStudents) * 100).toFixed(1);

              return (
                <div key={institution.id} style={{ marginBottom: '10px' }}>
                  <span>{institution.name}</span>
                  <Tooltip title={"Verified-" + verifiedStudents + " " + "Not Verified-" + notVerifiedStudents + " Total-" + totalStudents}>
                    <Progress
                      percent={parseFloat(notVerifiedStudentsPercentage) + parseFloat(verifiedStudentsPercentage)}
                      success={{
                        percent: parseFloat(verifiedStudentsPercentage),
                      }}
                    />
                  </Tooltip>
                </div>
              );
            })}
          </Card>
        </Col>
        <Col xs={24} sm={8} md={8}>
          <Card title="Total Verified Students">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Progress
                type="circle"
                percent={verifiedStudentsPercentage}
                success={{
                  percent: verifiedStudentsPercentage,
                }}
              />
            </div>
          </Card>
          <Card title="Family Details Updated" style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Progress
                type="circle"
                percent={familyVerifiedStudentsPercentage}
                success={{
                  percent: familyVerifiedStudentsPercentage,
                }}
              />
            </div>
          </Card>
          <Card title="Image Updated" style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Progress
                type="circle"
                percent={imageUpdatedPercentage}
                success={{
                  percent: imageUpdatedPercentage,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AdminDashBoard;