// DrawerProvider.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Drawer } from 'antd';

const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [forceRemount, setForceRemount] = useState(false);

  useEffect(() => {
    if (!isDrawerOpen) {
      setForceRemount(false); // Reset forceRemount state when drawer is closed
    }
  }, [isDrawerOpen]);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setForceRemount(true); // Trigger remounting of Drawer component
  };

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, openDrawer, closeDrawer }}>
      {children} {/* Pass children directly */}
      {forceRemount && null} {/* Optional: Force remount by rendering null */}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};
