import AcademicDetails from "./academic/AcademicDetails";
import AdmissionDetails from "./admission/AdmissionDetails";
import BasicInfo from "./basic/BasicInfo";
import CareerAndPersonalDeveopment from "./careerAndPersonal/CareerAndPersonalDeveopment";
import Contributions from "./contributions/Contributions";
import EducationalDetails from "./eudcational_details/EducationalDetails";
import FamilyDetails from "./family/FamilyDetails";
import HealthDetails from "./health/HealthDetails";
import LanguageDetails from "./languages/LanguageDetails";
import ResearchDetails from "./reseach/AchivementDetails";
import Skill from "./skills/Skill";
import Suluk from "./suluk/Suluk";


const categories = [
    {
        key: '1',
        label: 'Basic Info',
        children: <BasicInfo/>,
      
    },
    {
        key: '2',
        label: 'Admission Details',
        children: <AdmissionDetails/>,
      
    },
    {
        key: '3',
        label: 'Family Info',
        children: <FamilyDetails/>,
    },
    {
        key: '15',
        label: 'Religious Education',
        children: <EducationalDetails/>,
    },
    {
        key: '4',
        label: 'Academic Information',
        children: <AcademicDetails/>,
    },
    // {
    //     key: '5',
    //     label: 'Health Info',
    //     children: <HealthDetails/>,
    // },
    // {
    //     key: '6',
    //     label: 'Career And Personal Development',
    //     children: <CareerAndPersonalDeveopment/>,
    // },
    // {
    //     key: '7',
    //     label: 'Research and Publication',
    //     children: <ResearchDetails/>,
    // },
    // {
    //     key: '8',
    //     label: 'Travel and Financial Details',
    //     children: <CareerAndPersonalDeveopment/>,
    // },
    {
        key: '9',
        label: 'Languages',
        children: <LanguageDetails/>,
    },
    // {
    //     key: '10',
    //     label: 'Community Engagement',
    //     children: <CareerAndPersonalDeveopment/>,
    // },
    // {
    //     key: '11',
    //     label: 'Technology and Online Presence',
    //     children: <CareerAndPersonalDeveopment/>,
    // },
    {
        key: '12',
        label: 'Achievements',
        children: <ResearchDetails/>,
    },
    {
        key: '13',
        label: 'Sulook',
        children: <Suluk/>,
    },
    {
        key: '14',
        label: 'Skills',
        children: <Skill/>,
    },
    {
        key: '16',
        label: 'Contributions',
        children: <Contributions/>,
    },
];


export default categories;