import React, { useEffect } from 'react';
import { useAllStudents } from '../../context/allStudentContext';
import { useInstitution } from '../../context/InstitutionContext';
import { Row, Col, Card, Statistic, Progress, Tooltip } from 'antd';
import { useUserData } from '../../context/userDataContext';
import { fetchAcademics, fetchStudents, fetchManagerInstituions } from './store';
import { useAllAcademic } from '../../context/allAcademicContext';

function ManagerDashboard() {
  const { allStudents, setAllStudents } = useAllStudents();
  const { institutionList, setInstitutionList } = useInstitution();
  const {setAllAcademic} = useAllAcademic();
  const { userData } = useUserData();

  useEffect(() => {
    if (userData.role) {
      fetchStudents({ setData: setAllStudents });
      fetchManagerInstituions({ setData: setInstitutionList });
      fetchAcademics({setData:setAllAcademic})
    }
  }, [userData, setAllStudents, setInstitutionList,setAllAcademic]);
  console.log(institutionList);

  const verifiedStudents = allStudents.filter((e) => e.adhaar !== undefined && e.status !== undefined);
  const notVerifiedStudents = allStudents.filter((e) => e.adhaar === undefined || e.status === undefined);

  

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Institutions"
              value={institutionList.length}
              valueStyle={{ color: '#9307f0' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Students"
              value={allStudents.length}
              valueStyle={{ color: '#0741f0' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Verified Students"
              value={verifiedStudents.length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Faculties"
              value={notVerifiedStudents.length}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>
     
    </>
  );
}

export default ManagerDashboard;
