import React, { useState, useEffect } from 'react';
import { Form, Select, Input, DatePicker, Button } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { addDataToFirestore, firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { religiousLevelList, streamList, academicStatusList, collegeList, levelList } from '../../../constants/constants';

function AddEducationForm() {
  const [form] = Form.useForm();
  const { currentStudent } = useCurrentStudent();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [institutionList, setInstitutionList] = useState([]);



  const handleCourseChange = (value) => {
    setSelectedCourse(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };


  const handleCollegeChange = (value) => {
    setSelectedCollege(value);
  };


  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.enrolled_year = values.enrolled_year.year();
      if (values.pass_year) {
        values.pass_year = values.pass_year.year();
      }

      if (currentStudent && currentStudent.userId) {
        values.student_id = currentStudent.userId;
        await addDataToFirestore(collection.EDUCATIONAL_COLLECTION, values);
        form.resetFields();
        successMessage('Successfully Added');
      } else {
        throw new Error('Current student ID is not defined');
      }
    } catch (errorInfo) {
      errorMessage('Failed to add data: ' + errorInfo.message);
    }
  };
  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        const querySnapshot = await firestore.collection(collection.INSTITUTIONS_COLLECTION).get();
        const fetchedData = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setInstitutionList(fetchedData);


      } catch (error) {
        console.error('Error fetching institutions:', error);
      }
    };

    const fetchCourses = async () => {
      try {
        const coursesSnapshot = await firestore.collection(collection.COURSES).get();
        const fetchedCourses = coursesSnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setCourseList(fetchedCourses);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };


    fetchCourses()
    fetchInstitutions();
  }, [currentStudent.institution]);

  var currentCourse = courseList.find((item) => item.id === selectedCourse);

  return (
    <Form
      layout="vertical"
      form={form}
      style={{ maxWidth: 600 }}
      initialValues={{}}>
      <Form.Item
        label="Course"
        name="course"
        rules={[{ required: true, message: 'Please select' }]}>
        <Select showSearch placeholder="Select course" onChange={handleCourseChange}>
          {courseList.map((course) => (
            <Select.Option key={course.id} value={course.id}>
              {course.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      



      {/* <Form.Item
        label="Stream"
        name="stream"
        rules={[{ required: true, message: 'Please select' }]}>
        <Select showSearch placeholder="Select stream" onChange={handleStreamChange}>
          {streamList.map((stream) => (
            <Select.Option key={stream.id} value={stream.id}>
              {stream.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}


      {/* <Form.Item
        label="Institution"
        name="institution"
        rules={[{ required: true, message: 'Please select' }]}>
        <Select showSearch placeholder="Select Institution" onChange={handleCollegeChange}>
          {institutionList.map((college) => (
            <Select.Option key={college.id} value={college.id}>
              {college.name}
            </Select.Option>
          ))}
          <Select.Option key={0} value={0}>
              Other
            </Select.Option>
        </Select>
        
      </Form.Item> */}

      {/* {selectedCollege === 0 && (
        <Form.Item
          label="Institution Name"
          name="other_Inst"
          rules={[{ required: true, message: 'Please enter' }]}>
          <Input placeholder="Institution Name" />
        </Form.Item>
      )} */}

      <Form.Item
        label="Enrolled Year"
        name="enrolled_year"
        rules={[{ required: true, message: 'Please select a Year' }]}>
        <DatePicker picker="year" />
      </Form.Item>

      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: 'Please select' }]}>
        <Select showSearch placeholder="Select Status" onChange={handleStatusChange}>
          {academicStatusList.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {selectedStatus === 1 && <Form.Item
        label="Current Year"
        name="current_year"
        rules={[{ required: true, message: 'Please select course' }]}
      >
        <Select >
          {[...Array(currentCourse.years).keys()].map(item => (
            <Select.Option key={item} value={item + 1}>
              {getOrdinalSuffix(item + 1)} Year
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      }

      {selectedStatus === 2 && (
        <div>
          <Form.Item
            label="Passout Year"
            name="pass_year"
            rules={[{ required: true, message: 'Please select a Year' }]}>
            <DatePicker picker="year" />
          </Form.Item>
          <Form.Item
            label="Percentage of Marks"
            name="total_marks"
            rules={[
              { required: true, message: 'Please enter' },
              {
                pattern: /^[0-9]{2}$/,
                message: 'Percentage must be a two-digit number',
              },
            ]}>
            <Input placeholder="Marks" />
          </Form.Item>
        </div>
      )}


      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddEducationForm;



function getOrdinalSuffix(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}