import React, { useState, useEffect } from 'react';
import { Card, Descriptions, Button, Drawer, Badge, Popconfirm } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import AddEducation from './AddEducation';
import { deleteEducationDetails, fetchEducationDetails, formatData } from './store';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function EducationalDetails() {
  const { currentStudent } = useCurrentStudent();
  const [academicData, setAcademicData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const { userData } = useUserData();


  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchEducationDetails({
      studentId: currentStudent.userId,
      setData: setAcademicData
    });

    // Unsubscribe from real-time updates when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentStudent.userId]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesSnapshot = await firestore.collection(collection.COURSES).get();
        const fetchedCourses = coursesSnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setCourseList(fetchedCourses);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [currentStudent.institution]);

  const handleDelete = async (documentId) => {
    try {
      await deleteEducationDetails({ id: documentId });
      // Refresh the data after deletion
      setAcademicData((prevData) => prevData.filter((item) => item.key !== documentId));
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const formattedData = formatData({ data: academicData, courseList });

  return (
    <div>
      {userData.role !== userTypes.STUDENT ?<AddEducation />:null}

      {formattedData.map((item) => (
        <div key={item.key} style={{ marginBottom: 10 }}>
          {item.status === "Ongoing" ? (
            <Badge.Ribbon text="ongoing" color='green'>
              <Card
                type='inner'
                title={item.Name}
                extra={
                  <Popconfirm
                    placement="left"
                    title="Remove?"
                    description="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleDelete(item.id)}
                  >
                    <Button danger>Delete</Button>
                  </Popconfirm>
                }
                style={{ marginLeft: 10 }}
              >
                <Drawer title='Update Academic Info' width={500} onClose={closeDrawer} open={isDrawerOpen}>
                  {/* Add your drawer content here */}
                </Drawer>
                <Descriptions layout='horizontal'>
                  {Object.keys(item).filter(key => key !== 'id').map((key) => (
                    <Descriptions.Item key={key} label={key}>{item[key]}</Descriptions.Item>
                  ))}
                </Descriptions>
              </Card>
            </Badge.Ribbon>
          ) : (
            <Card
              type='inner'
              title={item.Name}
              extra={
                <Popconfirm
                  placement="left"
                  title="Remove?"
                  description="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleDelete(item.id)}
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              }
              style={{ marginLeft: 10 }}
            >
              <Drawer title='Update Academic Info' width={500} onClose={closeDrawer} open={isDrawerOpen}>
                {/* Add your drawer content here */}
              </Drawer>
              <Descriptions layout='horizontal'>
                {Object.keys(item).filter(key => key !== 'id').map((key) => (
                  <Descriptions.Item key={key} label={key}>{item[key]}</Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          )}
        </div>
      ))}
    </div>
  );
}

export default EducationalDetails;
