import { firestore } from "../../../services/firebase";
import collection from "../../../constants/collection";
import { errorMessage ,successMessage} from '../../../utils/messages';


export const fetchFamilyDetails = ({ studentId, setData }) => {
    try {
        const query = firestore.collection(collection.STUDENTS_COLLECTION).doc(studentId);

        // Get data from the specific document
        return query.onSnapshot((doc) => {
            if (doc.exists) {
                // Document exists, extract its data
                const fetchedData = {
                    key: doc.id,
                    ...doc.data(),
                };
                setData(fetchedData); // Update state with fetched data
            } else {
                // Document doesn't exist
                errorMessage('Data not Updated')
            }
        }, (error) => {
            console.error("Error getting document:", error);
        });
    } catch (error) {
        console.error('Error fetching basic details:', error);
    }
};


export const formatFamilyData = (item) => {
   
    const formattedItem = {
        "Father's Name":item.father_passed_away?"Late. "+item.father: item.father,
    };
    if(item.father_occupation){
        formattedItem["Father's Occupation"] = item.father_occupation;
    }
    if(item.job_country){
        formattedItem[ "Job Country"] = item.job_country;
    }
    if(item.father_phone){
        formattedItem[ "Father's Phone"] = item.father_phone;
    }
    formattedItem["Mother's Name"] =item.mother_passed_away?"Late. "+item.mother: item.mother;
    if(item.mother_occupation){
        formattedItem["Mother's Occupation"] = item.mother_occupation;
    }
    if(item.mother_phone){
        formattedItem[ "Mother's Phone"] = item.mother_phone;
    }
    if(item.guardian){
        formattedItem[ "Guardian's Name"] = item.guardian;
    }
    if(item.guardian_occupation){
        formattedItem[ "Guardian's Occupation"] = item.guardian_occupation;
    }
    if(item.guardian_phone){
        formattedItem[ "Guardian's Phone"] = item.guardian_phone;
    }


    return formattedItem;

};

