import React from 'react';
import { Form, Input, Button, Divider, Select, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { countryList } from '../../../constants/countries';
import collection from '../../../constants/collection';
import { firestore } from '../../../services/firebase';
import { errorMessage, successMessage } from '../../../utils/messages';

function UpdateFamilyDetails({ currentStudent }) {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      // Convert moment objects to string
      if (values.father_date_of_passing) {
        values.father_date_of_passing = values.father_date_of_passing.format('YYYY-MM-DD');
      }
      if (values.mother_date_of_passing) {
        values.mother_date_of_passing = values.mother_date_of_passing.format('YYYY-MM-DD');
      }

      // Remove undefined or null values
      Object.keys(values).forEach((key) => {
        if (values[key] === undefined || values[key] === null) {
          delete values[key];
        }
      });

      if (!values.father_passed_away) {
        values.father_passed_away = false;
      }
      if (!values.mother_passed_away) {
        values.mother_passed_away = false;
      }
      await firestore
        .collection(collection.STUDENTS_COLLECTION)
        .doc(currentStudent.userId)
        .update(values);

      form.resetFields();
      successMessage('Successfully Updated');
    } catch (error) {
      errorMessage('Failed');
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        father: currentStudent.father,
        father_occupation: currentStudent.father_occupation,
        job_country: currentStudent.job_country,
        father_phone: currentStudent.father_phone,
        mother: currentStudent.mother,
        mother_occupation: currentStudent.mother_occupation,
        mother_phone: currentStudent.mother_phone,
        father_passed_away: currentStudent.father_passed_away,
        mother_passed_away: currentStudent.mother_passed_away,
        father_date_of_passing: currentStudent.father_date_of_passing ? moment(currentStudent.father_date_of_passing) : null,
        mother_date_of_passing: currentStudent.mother_date_of_passing ? moment(currentStudent.mother_date_of_passing) : null,
      }}
    >
      <Divider orientation="left">Father's Details</Divider>
      <Form.Item
        label="Name"
        name="father"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input placeholder="Name of Father" />
      </Form.Item>

      <Form.Item name="father_passed_away" valuePropName="checked">
        <Checkbox>Passed Away</Checkbox>
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.father_passed_away !== currentValues.father_passed_away}>
        {({ getFieldValue }) => {
          return getFieldValue('father_passed_away') ? (
            <Form.Item
              label="Date of Demise"
              name="father_date_of_passing"
              rules={[{ required: true, message: 'Please select the date of passing' }]}
            >
              <DatePicker placeholder="Select date" style={{ width: '100%' }} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Occupation"
                name="father_occupation"
                rules={[{ required: true, message: 'Please enter ' }]}
              >
                <Input placeholder="Occupation of Father" />
              </Form.Item>

              <Form.Item
                label="Job Country"
                name="job_country"
                rules={[{ required: true, message: 'Please select ' }]}
              >
                <Select showSearch>
                  {countryList.map(item => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Phone"
                name="father_phone"
                rules={[{ required: true, message: 'Please enter ' }]}
              >
                <Input placeholder="Phone Number of Father" />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>

      <Divider orientation="left">Mother's Details</Divider>
      <Form.Item
        label="Name"
        name="mother"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input placeholder="Name of Mother" />
      </Form.Item>

      <Form.Item name="mother_passed_away" valuePropName="checked">
        <Checkbox>Passed Away</Checkbox>
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.mother_passed_away !== currentValues.mother_passed_away}>
        {({ getFieldValue }) => {
          return getFieldValue('mother_passed_away') ? (
            <Form.Item
              label="Date of Demise"
              name="mother_date_of_passing"
              rules={[{ required: true, message: 'Please select the date of passing' }]}
            >
              <DatePicker placeholder="Select date" style={{ width: '100%' }} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Occupation"
                name="mother_occupation"
                rules={[{ required: true, message: 'Please enter ' }]}
              >
                <Input placeholder="Occupation of Mother" />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="mother_phone"
                rules={[{ required: true, message: 'Please enter ' }]}
              >
                <Input placeholder="Phone Number of Mother" />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>

      <Divider orientation="left">Guardian's Details</Divider>
      <Form.Item
        label="Name"
        name="guardian"
      >
        <Input placeholder="Name of Guardian" />
      </Form.Item>
      <Form.Item
        label="Occupation"
        name="guardian_occupation"
      >
        <Input placeholder="Occupation of Guardian" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="guardian_phone"
      >
        <Input placeholder="Phone Number of Guardian" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default UpdateFamilyDetails;
