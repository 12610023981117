import React, { useState } from 'react';
import { Form, Select, Input, DatePicker, Button } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { addDataToFirestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { termList, boardList, universityList, streamList, academicStatusList, collegeList, levelList, schoolList, hssStreamList } from '../../../constants/constants';

function AddCourseForm() {
    const [form] = Form.useForm();
    const { currentStudent } = useCurrentStudent();
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedStream, setSelectedStream] = useState(null);
    const [selectedCollege, setSelectedCollege] = useState(null);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [selectedSemType, setSelectedSemType] = useState(null);
    const [selectedSem, setSelectedSem] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);



    const handleLevelChange = (value) => {
        setSelectedLevel(value);
    };
    const handleSemtypeChange = (value) => {
        setSelectedSemType(value);
    };
    const handleSemChange = (value) => {
        setSelectedSem(value);
    };
    const handleYearChange = (value) => {
        setSelectedYear(value);
    };


    const handleStatusChange = (value) => {
        setSelectedStatus(value);
    };

    const handleStreamChange = (value) => {
        setSelectedStream(value);
    };

    const handleCollegeChange = (value) => {
        setSelectedCollege(value);
    };
    const handleSchoolChange = (value) => {
        setSelectedSchool(value);
    };

    const handleTermChange = (value) => {
        setSelectedTerm(value);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            values.enrolled_year = values.enrolled_year.year();
            if (values.pass_year) {
                values.pass_year = values.pass_year.year();
            }
            if(!values.register_number){
                values.register_number= ''
            }
            if(!values.university){
                values.university= ''
            }

            if (currentStudent && currentStudent.userId) {
                values.student_id = currentStudent.userId;
                await addDataToFirestore(collection.ACADEMIC_COLLECTION, values);
                form.resetFields();
                successMessage('Successfully Added');
            } else {
                throw new Error('Current student ID is not defined');
            }
        } catch (errorInfo) {
            errorMessage('Failed to add data: ' + errorInfo.message);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            style={{ maxWidth: 600 }}
            initialValues={{}}>
            <Form.Item
                label="Level"
                name="level"
                rules={[{ required: true, message: 'Please select' }]}>
                <Select showSearch placeholder="Select level" onChange={handleLevelChange}>
                    {levelList.map((level) => (
                        <Select.Option key={level.id} value={level.id}>
                            {level.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {(selectedLevel === 1 || selectedLevel === 2 || selectedLevel === 3) ? (
                <Form.Item
                    label="Board"
                    name="board"
                    rules={[{ required: true, message: 'Please select' }]}>
                    <Select showSearch placeholder="Select Board">
                        {boardList.map((board) => (
                            <Select.Option key={board.id} value={board.id}>
                                {board.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (
                <Form.Item
                    label="University"
                    name="university"
                    rules={[{ required: true, message: 'Please select' }]}>
                    
                    <Select showSearch placeholder="Select University">
                        {universityList.map((university) => (
                            <Select.Option key={university.id} value={university.id}>
                                {university.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            <Form.Item
                label="Study Mode"
                name="stream"
                rules={[{ required: true, message: 'Please select' }]}>
                <Select showSearch placeholder="Select stream" onChange={handleStreamChange}>
                    {streamList.map((stream) => (
                        <Select.Option key={stream.id} value={stream.id}>
                            {stream.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {(selectedStream === 1 && (selectedLevel === 1 || selectedLevel === 2 || selectedLevel === 3)) && (
                <Form.Item
                    label="School"
                    name="school"
                    rules={[{ required: true, message: 'Please select' }]}>
                    <Select showSearch placeholder="Select School" onChange={handleSchoolChange}>
                        {schoolList.map((college) => (
                            <Select.Option key={college.id} value={college.id}>
                                {college.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {(selectedSchool === 0 && (selectedLevel === 1 || selectedLevel === 2 || selectedLevel === 3)) && (
                <Form.Item
                    label="School Name"
                    name="other_school"
                    rules={[{ required: true, message: 'Please enter' }]}>
                    <Input placeholder="College Name" />
                </Form.Item>
            )}
            {selectedLevel ===3 && (
                <Form.Item
                    label="Stream"
                    name="other_school"
                    rules={[{ required: true, message: 'Please select' }]}>
                    <Select showSearch placeholder="Select Stream" >
                        {hssStreamList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {(selectedStream === 1 && (selectedLevel !== 1 && selectedLevel !== 2 && selectedLevel !== 3)) && (
                <Form.Item
                    label="College"
                    name="college"
                    rules={[{ required: true, message: 'Please select' }]}>
                    <Select showSearch placeholder="Select College" onChange={handleCollegeChange}>
                        {collegeList.map((college) => (
                            <Select.Option key={college.id} value={college.id}>
                                {college.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            {(selectedCollege === 0 && (selectedLevel !== 1 && selectedLevel !== 2 && selectedLevel !== 3)) &&(
                <Form.Item
                    label="College Name"
                    name="other_college"
                    rules={[{ required: true, message: 'Please enter' }]}>
                    <Input placeholder="College Name" />
                </Form.Item>
            )}

            {(selectedLevel !== 1 && selectedLevel !== 2 && selectedLevel !== 3) && (
                <>
                    <Form.Item
                        label="Year/Semester"
                        name="year_or_semester"
                        rules={[{ required: true, message: 'Please select' }]}>
                        <Select  placeholder="Select term" onChange={handleSemtypeChange}>
                            <Select.Option key="year" value="year">
                                Year
                            </Select.Option>
                            <Select.Option key="semester" value="semester">
                                Semester
                            </Select.Option>

                        </Select>
                    </Form.Item>

                    {selectedSemType == 'semester' && 
                    <Form.Item
                    label={
                        <span>
                            Course Duration
                            <br />
                            <small style={{ fontWeight: 'normal' }}>Total Number of semesters of this course</small>
                        </span>
                    }
                    name="total_sem_no"
                    rules={[{ required: true, message: 'Please select' }]}>
                    <Select  placeholder="Select One" onChange={handleSemChange}>
                    {[...Array(10).keys()].map((item) => (
                            <Select.Option key={item} value={item+1}>
                                {item +1 } Semester
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>}

                {selectedSemType == 'year' && 
                    <Form.Item
                    label={
                        <span>
                            Course Duration
                            <br />
                            <small style={{ fontWeight: 'normal' }}>Total Number of years of this course</small>
                        </span>
                    }
                    name="total_year_no"
                    rules={[{ required: true, message: 'Please select' }]}>
                    <Select  placeholder="Select One" onChange={handleYearChange}>
                    {[...Array(5).keys()].map((item) => (
                            <Select.Option key={item} value={item+1}>
                                {item +1 } Year
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>}

                    <Form.Item
                        label={
                            <span>
                                Course Name
                                <br />
                                <small style={{ fontWeight: 'normal' }}>Eg: BA English</small>
                            </span>
                        }
                        name="course_name"
                        rules={[{ required: true, message: 'Please enter' }]}>
                        <Input placeholder="Course Name" />
                    </Form.Item>
                </>
            )}
            {selectedLevel !== 1 &&
                <Form.Item
                    label="Register Number"
                    name="register_number"
                    rules={[{ 
                        required: (selectedLevel === 2 || selectedLevel === 3) ? false : true, message: 'Please enter'
                         }]}>
                    <Input placeholder="Register Number" />
                </Form.Item>}


            <Form.Item
                label="Enrolled Year"
                name="enrolled_year"
                rules={[{ required: true, message: 'Please select a Year' }]}>
                <DatePicker picker="year" />
            </Form.Item>

            <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: 'Please select' }]}>
                <Select showSearch placeholder="Select Status" onChange={handleStatusChange}>
                    {academicStatusList.map((status) => (
                        <Select.Option key={status.id} value={status.id}>
                            {status.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {selectedStatus === 2 && (
                <div>
                    <Form.Item
                        label="Passout Year"
                        name="pass_year"
                        rules={[{ required: true, message: 'Please select a Year' }]}>
                        <DatePicker picker="year" />
                    </Form.Item>
                    <Form.Item
                        label="Percentage of Marks"
                        name="total_marks"
                        rules={[
                            { required: true, message: 'Please enter' },
                            {
                                pattern: /^[0-9]{2}$/,
                                message: 'Percentage must be a two-digit number',
                            },
                        ]}>
                        <Input placeholder="Marks" />
                    </Form.Item>
                </div>
            )}

            {(selectedStatus === 1 && selectedSemType == 'semester') &&(
                <div>
                    <Form.Item
                        label="Current Semester"
                        name="current_semester"
                        rules={[{ required: true, message: 'Please select' }]}>
                        <Select showSearch placeholder="Select a Semester" onChange={handleTermChange}>
                            {[...Array(selectedSem).keys()].map((item) => (
                                <Select.Option key={item} value={item+1}>
                                    {getOrdinalSuffix(item+1)} Semester
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* {termList.map((term) => {
                        if (term.no_of_terms < selectedTerm) {
                            return (
                                <Form.Item
                                    key={term.id}
                                    label={`Percentage of Marks in ${getOrdinalSuffix(term.no_of_terms)} Term`}
                                    name={`${term.id}_marks`}
                                    rules={[
                                        { required: true, message: 'Please enter marks' },
                                        {
                                            pattern: /^[0-9]{2}$/,
                                            message: 'Percentage must be a two-digit number',
                                        },
                                    ]}>
                                    <Input placeholder="Marks" />
                                </Form.Item>
                            );
                        }
                        return null;
                    })} */}
                </div>
            )}
            {(selectedStatus === 1 && selectedSemType == 'year') &&(
                
                    <Form.Item
                        label="Current Year"
                        name="current_year"
                        rules={[{ required: true, message: 'Please select' }]}>
                        <Select showSearch placeholder="Select a Year" onChange={handleTermChange}>
                            {[...Array(selectedYear).keys()].map((item) => (
                                <Select.Option key={item} value={item+1}>
                                    {getOrdinalSuffix(item+1)} Year
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    
            )}

            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default AddCourseForm;

function getOrdinalSuffix(number) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}
