import React from 'react';
import { Table } from 'antd';

function AppTable({ columns, data ,pagination,style}) {
  // Add a sequential number column to the data
  const dataWithNumbers = data.map((item, index) => ({ ...item, number: index + 1 }));

  // Add a number column to the columns
  const columnsWithNumbers = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
      width:'5%'
    },
    ...columns,
  ];

  return (
    <Table
    pagination={pagination}
      columns={columnsWithNumbers}
      dataSource={dataWithNumbers}
      style={style}
    />
  );
}

export default AppTable;
