import React,{useState} from 'react'
import { Drawer , Button} from 'antd';
import AddLanguageForm from './AddLanguageForm';

function AddLanguage() {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const openDrawer = () => {
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={openDrawer} type='primary' style={{ marginBottom: 10 }}>Add</Button>
            <Drawer title='Add Language' width={500} onClose={closeDrawer} open={isDrawerOpen}>
            <AddLanguageForm/>
            </Drawer>
        </div>
    )
}

export default AddLanguage