import { useEffect } from 'react';
import { firestore } from '../services/firebase';
import { useInstitution } from '../context/InstitutionContext';

const useFetchInstitutions = () => {
  const { institutionList, setInstitutionList } = useInstitution();

  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        const querySnapshot = await firestore.collection('institutions').get();
        const fetchedData = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setInstitutionList(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const subscribeToUpdates = () => {
      return firestore.collection('institutions').onSnapshot((snapshot) => {
        const updatedData = snapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setInstitutionList(updatedData);
      });
    };

    fetchInstitutions();
    const unsubscribe = subscribeToUpdates();

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [setInstitutionList]);

  return { institutionList };
};

export default useFetchInstitutions;
