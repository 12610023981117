import React, { useState, useEffect } from 'react';
import { Select, Row, Col, Button, Table } from 'antd';
import { useInstitution } from '../../context/InstitutionContext';
import { useAllSkill } from '../../context/allSkillsContext';
import { academicStatusList, levelList, statusList } from '../../constants/constants';
import { useAllAcademic } from '../../context/allAcademicContext';
import { useAllStudents } from '../../context/allStudentContext';
import { useAllLanguage } from '../../context/allLanguageContext';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './BasicReports.css';
import { fetchLanguages, stateList, formatSkillType, fetchSkills } from './store';
import { useUserData } from '../../context/userDataContext';
import userTypes from '../../constants/userTypes';
import {
    filterAndMapDataForAcademics,
    filterAndMapDataForFatherJobCountry,
    filterAndMapDataForLanguage,
    filterAndMapDataForLocation,
    filterAndMapDataForMaternalOrphan,
    filterAndMapDataForOrphan,
    filterAndMapDataForSayyid,
    generateColumnsForAcademics,
    generateColumnsForFatherJobCountry,
    generateColumnsForLanguage,
    generateColumnsForLocation,
    generateColumnsForMaternalOrphan,
    generateColumnsForOrphan,
    generateColumnsForSayyid,
    generateColumnsForSkills,
    filterAndMapDataForSkills,
    generateColumnsForBlood,
    filterAndMapDataForBlood,
    generateColumnsForAdmissionStatus,
    filterAndMapDataForAdmissionStatus,
} from './reportUtils';
import { languageList } from '../student_details/languages/store';
import { countryList } from '../../constants/countries';
import { skills } from '../student_details/skills/store';
import { bloodList } from '../../constants/blood';

const { Option } = Select;

function BasicReports() {
    const { userData } = useUserData();
    const { institutionList } = useInstitution();
    const { allAcademic } = useAllAcademic();
    const { allStudents } = useAllStudents();
    const { allLanguage, setAllLanguage } = useAllLanguage();
    const { allSkill, setAllSkill } = useAllSkill();
    const [selectedInst, setSelectedInst] = useState(userData.role === userTypes.INSTITUTION_HEAD ? userData.institution : null);
    const [selectedSecondField, setSelectedSecondField] = useState(null);
    const [selectedAcademicLevel, setSelectedAcademicLevel] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedBlood, setSelectedBlood] = useState(null);
    const [selectedAdmissionStatus, setSelectedAdmissionStatus] = useState(null);
    const [selectedSkillType, setSelectedSkillType] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [filteredSkills, setFilteredSkills] = useState([]);
    const [selectedAcademicStatus, setSelectedAcademicStatus] = useState(null);
    const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedLocalBody, setSelectedLocalBody] = useState(null);
    const [isIndia, setIsIndia] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [combinedData, setCombinedData] = useState([]);
    const [columns, setColumns] = useState([]);

    const secondField = [
        { id: 1, name: "Academics" },
        // { id: 2, name: "Religious" },
        { id: 3, name: "Location" },
        { id: 4, name: "Sayyid" },
        { id: 5, name: "Orphan" },
        { id: 7, name: "Maternal orphan" },
        { id: 6, name: "Language" },
        { id: 8, name: "Parent Job Country" },
        { id: 9, name: "Skills" },
        { id: 10, name: "Blood Group" },
        { id: 11, name: "Achievement" },
        { id: 12, name: "Admission Status" },
    ];



    const handleInstChange = (value) => {
        setSelectedInst(value);
    };

    const handleSecondFieldChange = (value) => {
        setSelectedSecondField(value);
        setSelectedState(null);
        setSelectedDistrict(null);
        setSelectedLocalBody(null);
    };

    const handleAcademicLevelChange = (value) => {
        setSelectedAcademicLevel(value);
    };
    const handleLanguageChange = (value) => {
        setSelectedLanguage(value);
    };
    const handleSkillTypeChange = (type) => {
        setSelectedSkillType(type);
        setFilteredSkills(skills.filter(skill => skill.type === type));
    };
    const handleSkillChange = (skill) => {
        setSelectedSkill(skill);
    };
    const handleBloodChange = (value) => {
        setSelectedBlood(value);
    };
    const handleAdmissionStatusChange = (value) => {
        setSelectedAdmissionStatus(value);
    };
    const handleAcademicStatusChange = (value) => {
        setSelectedAcademicStatus(value);
    };
    const handleAcademicYearChange = (value) => {
        setSelectedAcademicYear(value);
    };

    const handleStateChange = (value) => {
        setSelectedState(value);
        setSelectedDistrict(null);
        setSelectedLocalBody(null);
    };

    const handleDistrictChange = (value) => {
        setSelectedDistrict(value);
        setSelectedLocalBody(null);
    };

    const handleLocalBodyChange = (value) => {
        setSelectedLocalBody(value);
    };
    const handleCountryChange = (value) => {
        setSelectedCountry(value);
    };
    const handleIndiaChange = (value) => {
        setIsIndia(value);
    };

    useEffect(() => {
        fetchLanguages({ setData: setAllLanguage });
        fetchSkills({ setData: setAllSkill });
    }, [setAllLanguage, setAllSkill]);

    const handleSearch = () => {

        let newColumns = [];
        let filteredData = [];

        switch (selectedSecondField) {
            case 1:
                newColumns = generateColumnsForAcademics();
                filteredData = filterAndMapDataForAcademics(allStudents, allAcademic, institutionList, levelList, academicStatusList, selectedInst, selectedAcademicLevel, selectedAcademicStatus, selectedAcademicYear);
                break;
            case 3:
                newColumns = generateColumnsForLocation();
                filteredData = filterAndMapDataForLocation(allStudents, selectedInst, selectedState, selectedDistrict, selectedLocalBody);
                break;
            case 4:
                newColumns = generateColumnsForSayyid();
                filteredData = filterAndMapDataForSayyid(allStudents, institutionList, selectedInst);
                break;
            case 5:
                newColumns = generateColumnsForOrphan();
                filteredData = filterAndMapDataForOrphan(allStudents, institutionList, selectedInst);
                break;
            case 6:
                newColumns = generateColumnsForLanguage();
                filteredData = filterAndMapDataForLanguage(allStudents, allLanguage, institutionList, selectedInst, selectedLanguage);
                break;
            case 7:
                newColumns = generateColumnsForMaternalOrphan();
                filteredData = filterAndMapDataForMaternalOrphan(allStudents, institutionList, selectedInst);
                break;
            case 8:
                newColumns = generateColumnsForFatherJobCountry();
                filteredData = filterAndMapDataForFatherJobCountry(allStudents, institutionList, selectedInst, selectedCountry, isIndia);
                break;
            case 9:
                newColumns = generateColumnsForSkills();
                filteredData = filterAndMapDataForSkills(allStudents, institutionList, selectedInst, allSkill, selectedSkill);
                break;
            case 10:
                newColumns = generateColumnsForBlood();
                filteredData = filterAndMapDataForBlood(allStudents, institutionList, selectedInst, selectedBlood);
                break;
            // case 11:
            //     newColumns = generateColumnsForBlood();
            //     filteredData = filterAndMapDataForBlood(allStudents, institutionList, selectedInst, selectedBlood);
            //     break;

            case 12:
                newColumns = generateColumnsForAdmissionStatus();
                filteredData = filterAndMapDataForAdmissionStatus(allStudents, institutionList, selectedInst, selectedAdmissionStatus);
                break;

            default:
                // Handle default case or throw error if necessary
                break;
        }

        setColumns(newColumns);
        setCombinedData(filteredData);
    };


    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(combinedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Report.xlsx');
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [columns.map(col => col.title)],
            body: combinedData.map(row => columns.map(col => row[col.dataIndex])),
        });
        doc.save('Report.pdf');
    };

    return (
        <div style={{ paddingBottom: (selectedSecondField === 1 && selectedAcademicLevel) ? '16px' : '0' }}>
            <Row gutter={16}>
                <Col span={8}>
                    <Select
                        showSearch
                        disabled={userData.role === userTypes.INSTITUTION_HEAD}
                        defaultValue={userData.role === userTypes.INSTITUTION_HEAD ? userData.institution : undefined}
                        placeholder="Select Institution"
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={handleInstChange}
                    >
                        <Option key={0} value={0}>
                            All
                        </Option>
                        {institutionList.map(institution => (
                            <Option key={institution.id} value={institution.id}>
                                {institution.name}
                            </Option>
                        ))}
                    </Select>

                </Col>
                <Col span={8}>
                    <Select
                        placeholder="Select Option 2"
                        style={{ width: '100%' }}
                        onChange={handleSecondFieldChange}
                    >
                        {secondField.map(item => (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                {selectedSecondField === 1 && (
                    <Col span={8}>
                        <Select
                            placeholder="Select a level"
                            style={{ width: '100%' }}
                            onChange={handleAcademicLevelChange}>
                            {levelList.map(item => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                )}

                {(selectedSecondField === 1 && selectedAcademicLevel) && (
                    <Col span={8} style={{ marginTop: 10 }}>
                        <Select
                            placeholder="Select status"
                            style={{ width: '100%' }}
                            onChange={handleAcademicStatusChange}>
                            {academicStatusList.map(item => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                )}
                {(selectedAcademicStatus === 1 && selectedSecondField === 1) && (
                    <Col span={8} style={{ marginTop: 10 }}>
                        <Select
                            placeholder="Select Year"
                            style={{ width: '100%' }}
                            onChange={handleAcademicYearChange}>
                            {[...new Set(allAcademic
                                .map(academic => academic.current_year)
                                .filter(current_year => current_year !== null && current_year !== undefined && current_year !== '')
                                .sort((a, b) => a - b)
                            )]
                                .map(current_year => (
                                    <Option key={current_year} value={current_year}>
                                        {current_year}
                                    </Option>
                                ))}

                        </Select>
                    </Col>
                )}


                {selectedSecondField === 3 && (
                    <>
                        <Col span={8} >
                            <Select
                                placeholder="Select State"
                                style={{ width: '100%' }}
                                onChange={handleStateChange}>
                                {[...new Set(allStudents.map(student => student.state))]
                                    .filter(state => state !== null && state !== undefined && state !== '')
                                    .map(state => (
                                        <Option key={state} value={state}>
                                            {stateList.find(e => e.id === state)?.name}
                                        </Option>
                                    ))}
                            </Select>

                        </Col>
                        {selectedState && (
                            <Col span={8} style={{ marginTop: 10 }}>
                                <Select showSearch
                                    placeholder="Select District"
                                    style={{ width: '100%' }}
                                    onChange={handleDistrictChange}>
                                    {[...new Set(allStudents.filter(student => student.state === selectedState).map(student => student.district))].map(district => (
                                        <Option key={district} value={district}>
                                            {district}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                        {selectedDistrict && (
                            <Col span={8} style={{ marginTop: 10 }}>
                                <Select showSearch
                                    placeholder="Select Local Body"
                                    style={{ width: '100%' }}
                                    onChange={handleLocalBodyChange}>
                                    {[...new Set(allStudents.filter(student => student.district === selectedDistrict).map(student => student.local_body))].map(local_body => (
                                        <Option key={local_body} value={local_body}>
                                            {local_body}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                    </>
                )}
                {selectedSecondField === 6 && (
                    <>
                        <Col span={8} >
                            <Select
                                placeholder="Select Language"
                                style={{ width: '100%' }}
                                onChange={handleLanguageChange}>
                                {languageList.map(item => (
                                    <Option key={item.id} value={item.name}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>

                        </Col>


                    </>
                )}
                {selectedSecondField === 8 &&
                    <Col span={8} >
                        <Select
                            placeholder="Select One"
                            style={{ width: '100%' }}
                            onChange={handleIndiaChange}>
                            <Option key={1} value={true}>
                                India
                            </Option>
                            <Option key={0} value={false}>
                                Out of India
                            </Option>
                        </Select>
                    </Col>}
                {(selectedSecondField === 8 && !isIndia) &&
                    <Col span={8} >
                        <Select
                            placeholder="Select Country"
                            style={{ width: '100%', marginTop: 10 }}
                            onChange={handleCountryChange}>
                            {[...new Set(allStudents.map(student => student.job_country))]
                                .filter(state => state !== null && state !== undefined && state !== '' && state !== 'India')
                                .map(state => (
                                    <Option key={state} value={state}>
                                        {countryList.find(e => e.name === state)?.name}
                                    </Option>
                                ))}

                        </Select>
                    </Col>}


                {selectedSecondField === 9 && (
                    <>
                        <Col span={8}>
                            <Select
                                placeholder="Skill Type"
                                style={{ width: '100%' }}
                                onChange={handleSkillTypeChange}>
                                {[...new Set(skills.map(item => item.type))].map((type, index) => (
                                    <Option key={index} value={type}>
                                        {formatSkillType(type)}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        {selectedSkillType && (
                            <Col span={8} style={{ marginTop: 10 }}>
                                <Select
                                    placeholder="Skill"
                                    style={{ width: '100%' }}
                                    onChange={handleSkillChange}>
                                    {filteredSkills.map((skill, index) => (
                                        <Option key={index} value={skill.name}>
                                            {skill.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                    </>
                )}

                {selectedSecondField === 10 && (
                    <>
                        <Col span={8} >
                            <Select
                                placeholder="Select Blood Group"
                                style={{ width: '100%' }}
                                onChange={handleBloodChange}>
                                {bloodList.map(item => (
                                    <Option key={item.name} value={item.name}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </>
                )}
                {selectedSecondField === 12 && (
                    <>
                        <Col span={8} >
                            <Select
                                placeholder="Select Status"
                                style={{ width: '100%' }}
                                onChange={handleAdmissionStatusChange}>
                                {statusList.map(item => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </>
                )}




                <Col span={8} style={{ marginTop: 10 }}>
                    <Button type='primary' onClick={handleSearch}>Search</Button>
                </Col>
            </Row>
            <div style={{ marginTop: 20 }}>
                <h3>Data Count: {combinedData.length}</h3>
                <Table
                    columns={columns}
                    dataSource={combinedData}
                    style={{ marginTop: 10 }}
                    rowKey="id"
                />
            </div>
            <div className="floating-buttons">
                <Button onClick={downloadExcel} style={{ marginRight: 10 }}>Download Excel</Button>
                <Button onClick={downloadPDF}>Download PDF</Button>
            </div>
        </div>
    );
}

export default BasicReports;
