/* StudentDetails.jsx */
import React from 'react';
import { Card, Tabs, Spin } from 'antd';
import categories from './categories';
import { useCurrentStudent } from '../../context/studentContext';

function StudentDetails() {
  const { currentStudent } = useCurrentStudent();

  // Check if currentStudent is null or undefined
  if (!currentStudent) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card type='inner' title={currentStudent.name }  headStyle={{ backgroundColor: '#08a7fc', color: 'white' }}>
      <Tabs defaultActiveKey="1" items={categories} />
    </Card>
  );
}

export default StudentDetails;
