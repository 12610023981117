import React, { useState } from 'react';
import { Button, Form, Input, Select, Upload, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { firestore } from '../../../services/firebase';
import statesData from '../../../assets/json/states.json';
import districtData from '../../../assets/json/districts.json';
import localBodyData from '../../../assets/json/localBody.json';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { bloodList } from '../../../constants/blood';

function UpdateBasicInfoForm({ currentStudent }) {
    const [form] = Form.useForm();
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDist, setSelectedDist] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [ifSayyid, setIfSayyid] = useState(currentStudent.if_sayyid || false);
    const storage = firebase.storage();

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            values.if_sayyid = ifSayyid; // Ensure if_sayyid is included in the form values

            // Upload image to Firebase Storage
            if (fileList.length > 0) {
                const imageFile = fileList[0].originFileObj;
                const storageRef = storage.ref(`${collection.STUDENTS_IMAGES_COLLECTION}/${currentStudent.userId}`);
                const uploadTask = storageRef.put(imageFile);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // progress
                    },
                    (error) => {
                        // handle error
                        errorMessage('Error uploading file');
                    },
                    () => {
                        // upload complete
                        storageRef.getDownloadURL().then(async (url) => {
                            // Update the Firestore document with the image path
                            await firestore
                                .collection(collection.STUDENTS_COLLECTION)
                                .doc(currentStudent.userId)
                                .update({ ...values, imagePath: url });

                            form.resetFields();
                            successMessage('Successfully Updated');
                        });
                    }
                );
            } else {
                await firestore
                    .collection(collection.STUDENTS_COLLECTION)
                    .doc(currentStudent.userId)
                    .update({ ...values });

                form.resetFields();
                successMessage('Successfully Updated');
            }
        } catch (errorInfo) {
            errorMessage('Failed');
        }
    };

    const handleStateChange = (value) => {
        setSelectedState(value);
        form.setFieldsValue({ district: undefined });
    };
    const handleDistChange = (value) => {
        setSelectedDist(value);
        form.setFieldsValue({ local_body: undefined });
    };

    return (
        <Form
            layout="vertical"
            form={form}
            style={{ maxWidth: 600 }}
            initialValues={{
                name: currentStudent.name,
                place: currentStudent.place,
                nationality: 'indian',
                address: currentStudent.address,
                state: currentStudent.state,
                district: currentStudent.district,
                local_body: currentStudent.local_body,
                pincode: currentStudent.pincode,
                gender: currentStudent.gender,
                adhaar: currentStudent.adhaar,
                blood:currentStudent.blood,
                phone: currentStudent.phone,
                identification: currentStudent.identification,
                if_sayyid: currentStudent.if_sayyid,
            }}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter the name' }]}
            >
                <Input placeholder="Name of Student" />
            </Form.Item>
            <Form.Item name="if_sayyid" valuePropName="checked">
                <Checkbox checked={ifSayyid} onChange={(e) => setIfSayyid(e.target.checked)}>
                    If Sayyid
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Place"
                name="place"
                rules={[{ required: true, message: 'Please enter the place' }]}
            >
                <Input placeholder="Place of Student" />
            </Form.Item>
            <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: 'Please enter the address' }]}
            >
                <Input placeholder="Address of Student" />
            </Form.Item>
            <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: 'Please Select State' }]}
            >
                <Select
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={handleStateChange}
                >
                    {statesData.map((state) => (
                        <Select.Option key={state.id} value={state.id}>
                            {state.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="District"
                name="district"
                rules={[{ required: true, message: 'Please Select District' }]}
            >
                <Select
                    showSearch
                    placeholder="Select District"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    disabled={!selectedState}
                    onChange={handleDistChange}
                >
                    {selectedState && districtData
                        .filter((district) => district.state_id === selectedState)
                        .map((dist) => (
                            <Select.Option key={dist.id} value={dist.district}>
                                {dist.district}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Panchayath/Municipality/Corparation"
                name="local_body"
                rules={[{ required: true, message: 'Please Select Local Body' }]}
            >
                <Select
                    showSearch
                    placeholder="Select Local Body"
                    optionFilterProp="children"
                    disabled={!selectedDist}
                >
                    {selectedDist && localBodyData
                        .filter((local) => local.district === selectedDist)
                        .map((local_body) => (
                            <Select.Option key={local_body.id} value={local_body.local_body}>
                                {local_body.local_body}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Nationality"
                name="nationality"
                rules={[{ required: true, message: 'Please Select Nationality' }]}
            >
                <Select showSearch>
                    <Select.Option key="1" value="indian">
                        Indian
                    </Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Pincode"
                name="pincode"
                rules={[
                    { required: true, message: 'Please enter Pincode' },
                    {
                        pattern: /^[0-9]{6}$/, // Regex for exactly six numbers
                        message: 'Pincode must be a six-digit number',
                    },
                ]}
            >
                <Input placeholder="Pincode" />
            </Form.Item>
            <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true, message: 'Please enter Phone Number' }]}
            >
                <Input placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
                label="Adhaar Number"
                name="adhaar"
                rules={[{ required: true, message: 'Please enter Adhaar Number' }]}
            >
                <Input placeholder="Adhaar" />
            </Form.Item>
            <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: 'Please Select Gender' }]}
            >
                <Select showSearch>
                    <Select.Option key="1" value="male">
                        Male
                    </Select.Option>
                    <Select.Option key="2" value="female">
                        Female
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Blood Group"
                name="blood"
                rules={[{ required: true, message: 'Please Select One' }]}
            >
                <Select
                    showSearch
                    placeholder="Select Blood Group"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {bloodList.map((blood) => (
                        <Select.Option key={blood.name} value={blood.name}>
                            {blood.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            
            <Form.Item
                label="Identification Mark"
                name="identification"
                rules={[{ required: true, message: 'Identification Mark is required' }]}
            >
                <Input placeholder="Identification Mark" />
            </Form.Item>
            <Form.Item label="Add a photo">
                <Upload
                    fileList={fileList}
                    beforeUpload={() => false}
                    onChange={handleFileChange}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default UpdateBasicInfoForm;
