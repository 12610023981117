import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, TimePicker } from 'antd';
import { addAttendanceRecord } from './store';
import { useLocation } from 'react-router-dom';
import { useUserData } from '../../context/userDataContext';
import moment from 'moment';

const AddAttendanceForm = ({ onClose, activeBatch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { userData } = useUserData();
  const location = useLocation();
  const institutionId = userData.role === 'institution_head' ? userData.institution : location.state.instId;

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { name, dates, time } = values;
      const formattedTime = time.format('hh:mm A');
      const batchPromises = dates.map(date => {
        const formattedDate = date.format('YYYY-MM-DD');
        return addAttendanceRecord({
          name,
          date: formattedDate,
          time: formattedTime,
          institution: institutionId,
          batches: [activeBatch],
        });
      });
      await Promise.all(batchPromises);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Error adding document:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please input the name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="dates"
        label="Dates"
        rules={[{ required: true, message: 'Please select the dates!' }]}
      >
        <DatePicker multiple />
      </Form.Item>
      <Form.Item
        name="time"
        label="Time"
        rules={[{ required: true, message: 'Please select the time!' }]}
      >
        <TimePicker format="hh:mm A" use12Hours />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddAttendanceForm;