// institutionContext.js

import { createContext, useContext, useState, useEffect } from 'react';

export const CurrentInstitutionContext = createContext();

export const useCurrentInstitution = () => useContext(CurrentInstitutionContext); 

export const CurrentInstitutionProvider = ({ children }) => { 
  const [currentInstitution, setCurrentInstitution] = useState(null); 

  useEffect(() => {
    const storedData = localStorage.getItem('currentInstitution'); 
    if (storedData) {
      setCurrentInstitution(JSON.parse(storedData)); 
    }
  }, []);

  useEffect(() => {
    if (currentInstitution !== null) { 
      localStorage.setItem('currentInstitution', JSON.stringify(currentInstitution)); 
    }
  }, [currentInstitution]); 

  const setInstitutionData = (data) => { 
    setCurrentInstitution(data); 

  };

  return (
    <CurrentInstitutionContext.Provider value={{ currentInstitution, setInstitutionData }}> 
      {children}
    </CurrentInstitutionContext.Provider>
  );
};