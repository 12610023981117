// studentContext.js

import { createContext, useContext, useState, useEffect } from 'react';

export const CurrentStudentContext = createContext();

export const useCurrentStudent = () => useContext(CurrentStudentContext);

export const CurrentStudentProvider = ({ children }) => {
  const [currentStudent, setCurrentStudent] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('currentStudent');
    if (storedData) {
      setCurrentStudent(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (currentStudent !== null) {
      localStorage.setItem('currentStudent', JSON.stringify(currentStudent));
    }
  }, [currentStudent]);

  const setStudentData = (data) => {
    setCurrentStudent(data);

  };

  return (
    <CurrentStudentContext.Provider value={{ currentStudent, setStudentData }}>
      {children}
    </CurrentStudentContext.Provider>
  );
};
