import React, { useState } from 'react';
import { Form, Select, Button, Input } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { addDataToFirestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { eventList, levelList, rankOptions } from './store';


function AddAchivementForm() {
    const [form] = Form.useForm();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { currentStudent } = useCurrentStudent();
    const [selectedRank, setSelectedRank] = useState(null);


    const handleEventChange = (value) => {
        setSelectedEvent(value);
    };
    const handleRankChange = (value) => {
        setSelectedRank(value);
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();

            values.student_id = currentStudent.userId;
            values.link = values.link ?? "";
            addDataToFirestore(collection.ACHIEVEMENTS_COLLECTION, values);
            form.resetFields();
            successMessage('Successfully Added');

        } catch (errorInfo) {
            errorMessage("Filed");
        }
    };

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                style={{ maxWidth: 600 }}
                initialValues={{}}
            >

                <Form.Item
                    label="Level"
                    name="level"
                    rules={[{ required: true, message: 'Please Select' }]}
                >
                    <Select showSearch placeholder="Select a Level" >
                        {levelList.map((item) => (
                            <Select.Option key={item.id} value={item.name}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Event"
                    name="event"
                    rules={[{ required: true, message: 'Please Select' }]}
                >
                    <Select showSearch placeholder="Select an Event" onChange={handleEventChange}>
                        {eventList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedEvent === 0 &&
                    <Form.Item
                        label="Event Name"
                        name="other_event"
                        rules={[{ required: true, message: 'Please Enter' }]}
                    >
                        <Input placeholder='Event Name' />
                    </Form.Item>
                }
                {selectedEvent === 1 &&
                    <Form.Item
                        label="Rank"
                        name="rank"
                        rules={[{ required: true, message: 'Please Select' }]}
                    >
                        <Select onChange={handleRankChange}>
                            {rankOptions.map(option => (
                                <Select.Option key={option.key} value={option.id}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                }

                {selectedRank !== 'Individual Champion' &&

                    <>

                        <Form.Item
                            label={
                                <span>
                                    Topic
                                    <br />
                                    <small style={{ fontWeight: 'normal' }}>Enter the item name in the case of Sahityotsav, enter the Research topic in the case of research and paper presentation</small>
                                </span>
                            }
                            name="topic"
                            rules={[{ required: true, message: 'Please Enter' }]}
                        >
                            <Input placeholder='Topic' />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please Enter' }]}
                            style={{ marginBottom: '12px' }}
                        >
                            <Input.TextArea rows={3} placeholder='Description' />
                        </Form.Item>

                        <Form.Item
                            label="Link"
                            name="link"
                        >
                            <Input placeholder='Link' />
                        </Form.Item>

                    </>
                }



                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default AddAchivementForm;
