import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Card, Descriptions, Button, Drawer } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import UpdateBasicInfoForm from './UpdateBasicInfoForm';
import { fetchBasicDetails, formatBasicData } from './store';
import {AlertOutlined}  from '@ant-design/icons';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function BasicInfo() {
  const { currentStudent } = useCurrentStudent();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [basicData, setBasicData] = useState({});
  const {userData} = useUserData();

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchBasicDetails({
      studentId: currentStudent.userId,
      setData: setBasicData
    });

    // Unsubscribe from real-time updates when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentStudent.userId]);

  const formattedData = formatBasicData(basicData);
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col span={4} style={{ textAlign: 'center' }}>
          <Image width="100%" src={basicData.imagePath} />
          <p style={{ marginBottom: '0' }}>Student's ID Number:</p>
          <h3 style={{ margin: '0' }}>{basicData.StudentID}</h3>
        </Col>

        <Col span={20}>
          <Card
            type="inner"
            title="Basic Info"
            extra={<>
          {basicData.if_sayyid && <AlertOutlined  style={{paddingRight:10,color:'#b89112'}}/>}
           {userData.role !== userTypes.STUDENT? <Button onClick={openDrawer}>Update</Button>:null}
            </>}
            style={{ marginLeft: 10 }}
          >
            <Drawer title="Update Basic Info" width={500} onClose={closeDrawer} open={isDrawerOpen}>
              <UpdateBasicInfoForm currentStudent={basicData} />
            </Drawer>

            <Descriptions layout="horizontal">
              {Object.entries(formattedData).map(([title, content]) => (
                <Descriptions.Item label={title}>{content}</Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BasicInfo;
