import React, { createContext, useContext, useState, useEffect } from 'react';

const AllReligiousContext = createContext();

export const AllReligiousProvider = ({ children }) => {
  const [allReligious, setAllReligious] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedReligious = localStorage.getItem('allReligious');
    return savedReligious ? JSON.parse(savedReligious) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allStudents changes
    localStorage.setItem('allReligious', JSON.stringify(allReligious));
  }, [allReligious]);

  return (
    <AllReligiousContext.Provider value={{ allReligious, setAllReligious }}>
      {children}
    </AllReligiousContext.Provider>
  );
};
export const useAllReligious = () => {
    return useContext(AllReligiousContext);
  };
  