import React, { createContext, useContext, useState, useEffect } from 'react';

const AllSkillContext = createContext();

export const AllSkillProvider = ({ children }) => {
  const [allSkill, setAllSkill] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedSkill = localStorage.getItem('AllSkill');
    return savedSkill ? JSON.parse(savedSkill) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allSkill changes
    localStorage.setItem('AllSkill', JSON.stringify(allSkill));
  }, [allSkill]);

  return (
    <AllSkillContext.Provider value={{ allSkill, setAllSkill }}>
      {children}
    </AllSkillContext.Provider>
  );
};

export const useAllSkill = () => {
  return useContext(AllSkillContext);
};