import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

export const fetchStaffs = async ({ setData, filter = {} }) => {
  try {
    let query = firestore.collection(collection.STAFF_COLLECTION);

    // Apply filter if provided
    if (filter.institution) {
      query = query.where('institution', '==', filter.institution);
    }

    const unsubscribe = query.onSnapshot(snapshot => {
      const staffs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log(staffs);
      setData(staffs);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching staffs:', error);
  }
};

export const formatData = (data, institutionList) => {
  console.log(institutionList);
  return data.map(item => {
      const findInstitution = (id) => institutionList.find((inst) => inst.id === id);
      const formattedItem = {
          "name": item.name,
      };
      if (item.institution) {
          const institution = findInstitution(item.institution);
          formattedItem["institution"] = institution ? institution.name : "Unknown Institution";
      } else {
          formattedItem["institution"] = "Ma'din Academy";
      }

      return formattedItem;
  });
};

// function capitalizeWords(str) {
//   return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
// }