import { Button } from 'antd';
import React from 'react'

function AppButton({onClick, text}) {
  
  return (
    <div className="App">
    <Button type="primary" onClick={onClick}>{text}</Button>
  </div>
  )
}

export default AppButton