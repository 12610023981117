import React, { useState, useEffect } from 'react';
import { Avatar, List, Button, Row, Col, Drawer } from 'antd';
import { firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import AddContributionForm from './AddContributionForm';
import { useCurrentStudent } from '../../../context/studentContext';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function Contributions() {
    const [data, setData] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const { currentStudent } = useCurrentStudent();
    const {userData} = useUserData();



    const addData = () => {
        setDrawerVisible(true);
    };

    const onClose = () => {
        setDrawerVisible(false);
    };

    useEffect(() => {
        const unsubscribe = firestore.collection(collection.CONTRIBUTIONS_COLLECTION)
        .where('student_id', '==', currentStudent.userId)
            .onSnapshot((snapshot) => {
                const fetchedContributions = snapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));
                setData(fetchedContributions);
            }, (error) => {
                console.error('Error fetching contributions:', error);
            });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return (
        <>
          {userData.role !== userTypes.STUDENT ?  <Row justify="end" style={{ marginBottom: '16px' }}>
                <Col>
                    <Button onClick={addData} type="primary">
                        Add Contribution
                    </Button>
                </Col>
            </Row>:null}
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={`https://cdn-icons-png.flaticon.com/512/3529/3529189.png`} />}
                            title={item.project}
                            description={item.amount}
                        />
                    </List.Item>
                )}
            />
            <Drawer
                title="Add Contribution"
                width={500}
                onClose={onClose}
                open={drawerVisible}
            >
                <AddContributionForm />
            </Drawer>
        </>
    )
}

export default Contributions;