import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { resons, statusList, typeList, universityList } from '../../../constants/constants';
import { formatDOB } from '../../../utils/formatDOB';
import { useUserData } from '../../../context/userDataContext';
import moment from 'moment';
import userTypes from '../../../constants/userTypes';

function UpdateAdmissionForm({ currentStudent }) {
    const [form] = Form.useForm();
    const { userData } = useUserData();
    const [institutionList, setInstitutionList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(currentStudent.admitted_course ?? null);
    const [courseList, setCourseList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherValue, setOtherValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedReson, setSelectedReson] = useState(null);
    const [isRecommended, setIsRecommended] = useState(currentStudent.recommended);


    const handleSelectChange = (value) => {
        if (value === 0) {
            setIsOtherSelected(true);
        } else {
            setIsOtherSelected(false);
        }
    };
    const handleSelectedStatus = (value) => {
        setSelectedStatus(value)
    };
    const handleSelectedReason = (value) => {
        setSelectedReson(value)
    };
    const handleCourseChange = (value) => {
        var course = courseList.find((e) => e.id === value);
        setSelectedCourse(course);
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            if (values.admission_date) {
                values.admission_date = values.admission_date.toDate(); // Convert Moment object to Date object
            }
            if (values.entrance_test_date) {
                values.entrance_test_date = values.entrance_test_date.toDate(); // Convert Moment object to Date object
            }

            // Remove undefined values
            const filteredValues = Object.fromEntries(
                Object.entries(values).filter(([_, v]) => v !== undefined)
            );

            await firestore
                .collection(collection.STUDENTS_COLLECTION)
                .doc(currentStudent.userId)
                .update({ ...filteredValues });
            successMessage('Successfully Updated');
        } catch (error) {
            console.error('Error details:', error); // Log the error details
            errorMessage('Error updating admission details');
        }
    };

    var sortedCourseList = courseList.sort((a, b) => a.order - b.order);


    useEffect(() => {
        const fetchInstitutions = async () => {
            try {
                const querySnapshot = await firestore.collection(collection.INSTITUTIONS_COLLECTION).get();
                const fetchedData = querySnapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));
                setInstitutionList(fetchedData);

                // Fetch courses for the current student's institution
                const currentInstitution = fetchedData.find(inst => inst.key === currentStudent.institution);
                if (currentInstitution && currentInstitution.courses) {
                    fetchCourses(currentInstitution.courses);
                    fetchBatches(currentInstitution.id)
                }
            } catch (error) {
                console.error('Error fetching institutions:', error);
            }
        };

        const fetchCourses = async (courseIds) => {
            try {
                const coursesSnapshot = await firestore.collection(collection.COURSES)
                    .where('id', 'in', courseIds)
                    .get();
                const fetchedCourses = coursesSnapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));
                setCourseList(fetchedCourses);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };
        const fetchBatches = async (institutionId) => {
            try {
                const coursesSnapshot = await firestore.collection(collection.BATCHES_COLLECTION)
                    .where('institution', '==', institutionId)
                    .get();
                const fetchedBatches = coursesSnapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));
                setBatchList(fetchedBatches);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchInstitutions();
    }, [currentStudent.institution]);

    console.log(currentStudent);


    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                style={{ maxWidth: 600 }}
                initialValues={{
                    institution: currentStudent.institution,
                    batch: currentStudent.batch,
                    admitted_course: currentStudent.admitted_course,
                    status: currentStudent.status,
                    type: currentStudent.type,
                    admission_date: currentStudent.admission_date ? moment(formatDOB(currentStudent.admission_date)) : null,
                    previous_institution: currentStudent.previous_institution,
                    other_inst_name: currentStudent.other_inst_name,
                    admitted_course_year: currentStudent.admitted_course_year,
                    admission_number: currentStudent.admission_number,
                    entrance_marks: currentStudent.entrance_marks,
                    recommended: currentStudent.recommended,
                    recommended_by: currentStudent.recommended_by,
                    entrance_test_date: currentStudent.entrance_test_date ? moment(formatDOB(currentStudent.entrance_test_date)) : null,

                }}
            >
                <Form.Item
                    label="Institution"
                    name="institution"
                    rules={[{ required: true, message: 'Please select institution' }]}
                >
                    <Select disabled>
                        {institutionList.map(institution => (
                            <Select.Option key={institution.key} value={institution.key}>
                                {institution.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Batch"
                    name="batch"
                    rules={[{ required: true, message: 'Please select batch' }]}
                >
                    <Select >
                        {batchList.map(institution => (
                            <Select.Option key={institution.key} value={institution.key}>
                                {institution.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Admission Number"
                    name="admission_number"
                    rules={[
                        { required: true, message: 'Please enter Admission Number' },
                        {
                            pattern: /^\d+$/, // one or more digits
                            message: 'It must be a number',
                        },
                    ]}

                >
                    <Input placeholder="Adminssion Number" />
                </Form.Item>

                <Form.Item
                    label="Admission Date"
                    name="admission_date"
                    rules={[{ required: true, message: 'Please select a date' }]}
                >
                    <DatePicker format="DD-MM-YYYY" />
                </Form.Item>

                <Form.Item
                    label="Admitted Course"
                    name="admitted_course"
                    rules={[{ required: true, message: 'Please select course' }]}
                >
                    <Select onChange={handleCourseChange}>
                        {sortedCourseList.map(course => (
                            <Select.Option key={course.key} value={course.key}>
                                {course.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedCourse && <Form.Item
                    label={
                        <span>
                            Course's Year
                            <br />
                            <small style={{ fontWeight: 'normal' }}>Eg: if joined to 9th standard select High School from Course and 2nd from Year</small>
                        </span>
                    }
                    name="admitted_course_year"
                    rules={[{ required: true, message: 'Please select course' }]}
                >
                    <Select >
                        {[...Array(selectedCourse.years).keys()].map(item => (
                            <Select.Option key={item} value={item + 1}>
                                {getOrdinalSuffix(item + 1)} Year
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                }

                <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: 'Please select status' }]}
                >
                    <Select onChange={handleSelectedStatus}>
                        {statusList.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedStatus === 3 &&
                    <Form.Item
                        label="Reason"
                        name="drop_our_reason"
                        rules={[{ required: true, message: 'Please select reason' }]}
                    >
                        <Select onChange={handleSelectedReason}>
                            {resons.map(item => (
                                <Select.Option key={item.name} value={item.name}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>}

                {selectedReson === "Higher Education" &&
                    <Form.Item
                        label="University"
                        name="higher_usity"
                        rules={[{ required: true, message: 'Please select University' }]}
                    >
                        <Select showSearch>
                            {universityList.map(item => (
                                <Select.Option key={item.id} value={item.name}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>}

                <Form.Item
                    label="Marks in Entrance Test"
                    name="entrance_marks"
                >
                    <Input placeholder="Marks"
                        disabled={userData.role === userTypes.STUDENT}
                    />

                </Form.Item>
                <Form.Item
                    label="Entrance Test Date"
                    name="entrance_test_date"
                >
                    <DatePicker
                        format="DD-MM-YYYY"
                        disabled={userData.role === userTypes.STUDENT}
                    />
                </Form.Item>

                <Form.Item
                    name="recommended"
                    valuePropName="checked"
                >
                    <Checkbox
                        disabled={userData.role === userTypes.STUDENT}
                        onChange={(e) => setIsRecommended(e.target.checked)}
                    >
                        Recommended
                    </Checkbox>
                </Form.Item>
                {isRecommended && (
                    <Form.Item
                        label="Recommended By"
                        name="recommended_by"
                        rules={[{ required: true, message: 'Please input the recommender\'s name' }]}
                    >
                        <Input
                            disabled={userData.role === userTypes.STUDENT}
                            placeholder="Recommender's Name" />
                    </Form.Item>
                )}

                <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true, message: 'Please select type' }]}
                >
                    <Select>
                        {typeList.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Previous Institution"
                    name="previous_institution"
                    rules={[{ required: true, message: 'Please select Institution' }]}
                >
                    <Select onChange={handleSelectChange}>
                        {institutionList.map(item => (
                            <Select.Option key={item.key} value={item.key}>
                                {item.name}
                            </Select.Option>
                        ))}
                        <Select.Option key="0" value={0}>
                            Other
                        </Select.Option>
                    </Select>
                </Form.Item>
                {isOtherSelected && (
                    <Form.Item
                        label="Institution Name"
                        name="other_inst_name"
                        rules={[{ required: true, message: 'Please input the Name' }]}
                    >
                        <Input value={otherValue} onChange={(e) => setOtherValue(e.target.value)} />
                    </Form.Item>
                )}
                <Form.Item>
                    <Button type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default UpdateAdmissionForm;

function getOrdinalSuffix(number) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}
