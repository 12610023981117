import React, { useState } from 'react';
import { Form, InputNumber, Button, Select } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { addDataToFirestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { contributionsList } from './store';

function AddContributionForm() {
  const [form] = Form.useForm();
  const { currentStudent } = useCurrentStudent();

  const onFinish = async (values) => {
    try {
      if (currentStudent && currentStudent.userId) {
        values.student_id = currentStudent.userId;
        await addDataToFirestore(collection.CONTRIBUTIONS_COLLECTION, values);
        form.resetFields();
        successMessage('Contribution successfully added');
      } else {
        throw new Error('Current student ID is not defined');
      }
    } catch (error) {
      errorMessage('Failed to add contribution: ' + error.message);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Project"
        name="project"
        rules={[{ required: true, message: 'Please select a project' }]}
      >
        <Select>
          {contributionsList.map((contribution, index) => (
            <Select.Option key={index} value={contribution.name}>
              {contribution.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Amount"
        name="amount"
        rules={[{ required: true, message: 'Please enter an amount' }]}
      >
        <InputNumber min={0} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddContributionForm;