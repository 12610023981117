import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const InstitutionContext = createContext();

// Custom hook to use the Institution context
export const useInstitution = () => useContext(InstitutionContext);

// InstitutionProvider component
export const InstitutionProvider = ({ children }) => {
  const [institutionList, setInstitutionList] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedInstitutions = localStorage.getItem('institutionList');
    return savedInstitutions ? JSON.parse(savedInstitutions) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever institutionList changes
    localStorage.setItem('institutionList', JSON.stringify(institutionList));
  }, [institutionList]);

  return (
    <InstitutionContext.Provider value={{ institutionList, setInstitutionList }}>
      {children}
    </InstitutionContext.Provider>
  );
};
