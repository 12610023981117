import React, { useState, useEffect } from 'react';
import { Card, Drawer, Button, Col, Row, Statistic } from 'antd';
import AppButton from '../../components/app_button';
import AppTable from '../../components/app_table';
import AddInstitutionForm from './AddInstitutionForm';
import AddCourseForm from './AddCourseForm';
import UpdateInstitutionForm from './UpdateInstitutionForm';
import { useNavigate } from 'react-router-dom';
import { useInstitution } from '../../context/InstitutionContext';
import { useUserData } from '../../context/userDataContext';
import userTypes from '../../constants/userTypes';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';

function Institutions() {
  const [tableData, setTableData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const navigate = useNavigate();
  const {institutionList} = useInstitution();
  const {userData} = useUserData();

  const openDrawer = (formType, record = null) => {
    const formData = formType === 'UpdateInstitution' ? { type: formType, data: record } : { type: formType };
    setSelectedForm(formData);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedForm(null);
    setDrawerOpen(false);
  };

  const handleViewClick = (record) => {
    navigate(`/batches`, { state: { instId: record.id } });
  };

  const handleUpdateClick = (record) => {
    openDrawer('UpdateInstitution', record);
  };

  useEffect(() => {
    setTableData(institutionList);
  }, [institutionList]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <span>
          <Button type='link' onClick={() => handleViewClick(record)}><EyeOutlined /></Button>
          {userData.role === userTypes.MANAGER && (
            <Button type='link' onClick={() => handleUpdateClick(record)}><EditOutlined /></Button>
          )}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Total Institutions"
              value={tableData.length}
              valueStyle={{ color: '#9307f0' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Main Campuses"
              value={tableData.filter(inst => inst.type === 'Main').length}
              valueStyle={{ color: '#0741f0' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Off Campuses"
              value={tableData.filter(inst => inst.type === 'Off').length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Residential Campuses"
              value={tableData.filter(inst => inst.type === 'Residential').length}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>
      <Card
        title="Institutions"
        extra={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button style={{ marginRight: 10 }} onClick={() => openDrawer('Courses')}>
              Courses
            </Button>
            {(userData.role === userTypes.MANAGER ||userData.role === userTypes.ADMIN) && (
              <AppButton text='Add Institution' onClick={() => openDrawer('AddInstitution')} />
            )}
          </div>
        }
      >
        <Drawer
          title={
            selectedForm?.type === 'Courses' ? 'Courses' :
            selectedForm?.type === 'UpdateInstitution' ? 'Update Institution' :
            selectedForm?.type === 'AddInstitution' ? 'Add Institution' : 'Form'
          }
          width={500}
          onClose={closeDrawer}
          open={isDrawerOpen}
        >
          {
            selectedForm?.type === 'Courses' ? <AddCourseForm /> :
            selectedForm?.type === 'UpdateInstitution' ? <UpdateInstitutionForm institution={selectedForm.data} /> :
            selectedForm?.type === 'AddInstitution' ? <AddInstitutionForm /> : null
          }
        </Drawer>
        <AppTable columns={columns} data={tableData} pagination={false} />
      </Card>
    </div>
  );
}

export default Institutions;