
import collection from "../../../constants/collection";
import { firestore } from "../../../services/firebase";
import { errorMessage, successMessage } from "../../../utils/messages";


export const fetchLanguageDetails = ({ studentId, setData }) => {
  try {
    const query = firestore.collection(collection.LANGUAGE_COLLECTION).where('student_id', '==', studentId);

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching academic details:', error);
  }
};
export const deleteLanguageDetails = ({ id }) => {
  try {
   firestore.collection(collection.LANGUAGE_COLLECTION).doc(id).delete();

    successMessage('Language removed')
  } catch (error) {
    errorMessage("Failed to remove Language")
    console.error('Error fetching academic details:', error);
  }
};


export const formatLanguageData = (data) => {

  return data.map(item => {
    const formattedItem = {
      "key":item.id,
      "Language":item.language,
      "Readig":item.read,
      "Listening":item.listen,
      "Writing":item.write,
      "Speaking":item.speak,
      "Native":item.native? "Yes":"No",

    };
   

    return formattedItem;
  });
};

export const languageList = 
  [
    { "id": 1, "name": "Malayalam" }, 
    { "id": 2, "name": "English" }, 
    { "id": 3, "name": "Arabic" }, 
    { "id": 4, "name": "Urdu" }, 
    { "id": 5, "name": "Spanish" }, 
    { "id": 6, "name": "French" }, 
    { "id": 7, "name": "German" }, 
    { "id": 8, "name": "Chinese" }, 
    { "id": 9, "name": "Russian" },
    { "id": 10, "name": "Tamil" },
    { "id": 11, "name": "Kannada" },
    { "id": 12, "name": "Hindi" },
  ]