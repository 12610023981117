import { languageList } from "../student_details/languages/store";

export const generateColumnsForAcademics = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'student_name', key: 'student_name' },
        { title: 'Level', dataIndex: 'level', key: 'level' },
        { title: 'Course Name', dataIndex: 'course_name', key: 'course_name' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        { title: 'Student Institution', dataIndex: 'student_institution', key: 'student_institution' },
    ];
};


export const filterAndMapDataForAcademics = (allStudents, allAcademic, institutionList, levelList, academicStatusList, selectedInst, selectedAcademicLevel, selectedAcademicStatus, selectedAcademicYear) => {
    const combined = allAcademic.map(academic => {
        const student = allStudents.find(student => student.id === academic.student_id);
        return {
            ...academic,
            student_name: student ? student.name : 'Unknown',
            student_institution: student ? institutionList.find(e => student.institution === e.id)?.name : 'Unknown',
            level: academic.level ? levelList.find(e => academic.level === e.id)?.name : 'Unknown',
            status: academic.status ? academicStatusList.find(e => academic.status === e.id)?.name : 'Unknown',
        };
    });

    return combined.filter(item => {
        return (
            (selectedInst ? item.student_institution === institutionList.find(e => e.id === selectedInst)?.name : true) &&
            (selectedAcademicLevel ? item.level === levelList.find(e => e.id === selectedAcademicLevel)?.name : true) &&
            (selectedAcademicStatus ? item.status === academicStatusList.find(e => e.id === selectedAcademicStatus)?.name : true) &&
            (selectedAcademicYear ?
                (item.current_year === selectedAcademicYear ||
                    (item.current_semester === selectedAcademicYear * 2 - 1) ||
                    (item.current_semester === selectedAcademicYear * 2)) :
                true)
        );
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));

};



export const generateColumnsForLocation = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'student_name', key: 'student_name' },
        { title: 'State', dataIndex: 'state', key: 'state' },
        { title: 'District', dataIndex: 'district', key: 'district' },
        { title: 'Local Body', dataIndex: 'local_body', key: 'local_body' },
    ];
};


export const filterAndMapDataForLocation = (allStudents, selectedInst, selectedState, selectedDistrict, selectedLocalBody) => {
    return allStudents.filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true) &&
            (selectedState ? student.state === selectedState : true) &&
            (selectedDistrict ? student.district === selectedDistrict : true) &&
            (selectedLocalBody ? student.local_body === selectedLocalBody : true)
        );
    }).map((item, index) => ({
        ...item,
        sl_no: index + 1,
        student_name: item.name,
        state: item.state,
        district: item.district,
        local_body: item.local_body
    }));
};

export const generateColumnsForSayyid = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Place', dataIndex: 'place', key: 'place' },
        { title: 'Student Institution', dataIndex: 'institution_name', key: 'institution_name' },
    ];
};

export const filterAndMapDataForSayyid = (allStudents, institutionList, selectedInst) => {
    const sayyidStudents = allStudents.filter(student => student.if_sayyid === true);

    return sayyidStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true)
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};


export const generateColumnsForOrphan = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Place', dataIndex: 'place', key: 'place' },
        { title: 'Student Institution', dataIndex: 'institution_name', key: 'institution_name' },
    ];
};

export const filterAndMapDataForOrphan = (allStudents, institutionList, selectedInst) => {
    const sayyidStudents = allStudents.filter(student => student.father_passed_away === true);

    return sayyidStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true)
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};

export const generateColumnsForMaternalOrphan = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Place', dataIndex: 'place', key: 'place' },
        { title: 'Student Institution', dataIndex: 'institution_name', key: 'institution_name' },
    ];
};

export const filterAndMapDataForMaternalOrphan = (allStudents, institutionList, selectedInst) => {
    const sayyidStudents = allStudents.filter(student => student.mother_passed_away === true);

    return sayyidStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true)
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};


export const generateColumnsForLanguage = () => {
    return [
        {
            title: 'SL No',
            dataIndex: 'sl_no',
            key: 'sl_no',
        },
        {
            title: 'Student Name',
            dataIndex: 'student_name',
            key: 'student_name',
        },
        {
            title: 'Institution',
            dataIndex: 'student_institution',
            key: 'student_institution',
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
        },
        {
            title: 'Proficiency',
            dataIndex: 'proficiencyPercentage',
            key: 'proficiencyPercentage',
            render: (text) => `${text.toFixed(2)}%`
        }
    ];
};

export const filterAndMapDataForLanguage = (allStudents, allLanguage, institutionList, selectedInst, selectedLanguage) => {
    const combined = allLanguage.map(item => {
        const student = allStudents.find(student => student.id === item.student_id);
        const studentInstitution = student ? institutionList.find(e => student.institution === e.id)?.name : 'Unknown';

        // Calculate total proficiency score
        const totalProficiency = item.speak + item.read + item.listen + item.write;
        const maxScore = 400; // Since each skill is out of 100 and there are 4 skills
        const proficiencyPercentage = (totalProficiency / maxScore) * 100;

        return {
            ...item,
            student_name: student ? student.name : 'Unknown',
            student_institution: studentInstitution,
            proficiencyPercentage
        };
    });

    const filtered = combined.filter(item => {
        return (
            (selectedInst ? item.student_institution === institutionList.find(e => e.id === selectedInst)?.name : true) &&
            (selectedLanguage ? item.language === selectedLanguage : true)
        );
    });

    // Sort by descending order of proficiency percentage
    const sorted = filtered.sort((a, b) => b.proficiencyPercentage - a.proficiencyPercentage);

    return sorted.map((item, index) => ({ ...item, sl_no: index + 1 }));
};

export const generateColumnsForFatherJobCountry = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Institution', dataIndex: 'institution_name', key: 'institution_name' },
        { title: 'Father Name', dataIndex: 'father', key: 'father' },
        { title: 'Job Country', dataIndex: 'job_country', key: 'job_country' },
        { title: 'Father Phone', dataIndex: 'father_phone', key: 'father_phone' }
    ];
};


export const filterAndMapDataForFatherJobCountry = (allStudents, institutionList, selectedInst, selectedCountry, isIndia) => {
    const filteredStudents = isIndia ? allStudents.filter(student => student.job_country === 'India' && student.job_country != null && student.job_country !== undefined) :
        allStudents.filter(student => student.job_country !== 'India' && student.job_country != null && student.job_country !== undefined)

    return filteredStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true) &&
            (selectedCountry ? student.job_country === selectedCountry : true)
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};

export const generateColumnsForSkills = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Institution', dataIndex: 'institution_name', key: 'institution_name' },
    ];
};


export const filterAndMapDataForSkills = (allStudents, institutionList, selectedInst, allSkills, selectedSkill) => {
    const filteredStudents = allStudents.filter(student => 
        allSkills.some(skill => skill.student_id === student.id && skill.skills.includes(selectedSkill))
    );

    return filteredStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true) 
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};

export const generateColumnsForBlood = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Place', dataIndex: 'place', key: 'place' },
        { title: 'Institution', dataIndex: 'institution_name', key: 'institution_name' },
    ];
};

export const filterAndMapDataForBlood = (allStudents, institutionList, selectedInst,selectedBlood) => {
    const sayyidStudents = allStudents.filter(student => student.blood === selectedBlood);

    return sayyidStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true)
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};

export const generateColumnsForAdmissionStatus = () => {
    return [
        { title: 'Sl. No', dataIndex: 'sl_no', key: 'sl_no' },
        { title: 'Student Name', dataIndex: 'name', key: 'name' },
        { title: 'Place', dataIndex: 'place', key: 'place' },
        { title: 'Reason', dataIndex: 'drop_our_reason', key: 'drop_our_reason' },
        { title: 'Institution', dataIndex: 'institution_name', key: 'institution_name' },
        
    ];
};
export const filterAndMapDataForAdmissionStatus = (allStudents, institutionList, selectedInst,selectedStatus) => {
    const sayyidStudents = allStudents.filter(student => student.status === selectedStatus);

    return sayyidStudents.map(student => {
        return {
            ...student,
            institution_name: institutionList.find(e => e.id === student.institution)?.name
        };
    }).filter(student => {
        return (
            (selectedInst ? student.institution === selectedInst : true)
        );
    }).sort((a, b) => {
        if (a.institution_name < b.institution_name) return -1;
        if (a.institution_name > b.institution_name) return 1;
        return 0;
    }).map((item, index) => ({ ...item, sl_no: index + 1 }));
};