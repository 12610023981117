import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Card, Descriptions, Button, Drawer } from 'antd';
import { useCurrentStaff } from '../../../context/staffContext'; 
import { fetchBasicDetails, formatBasicData } from './store'; 

function StaffBasicInfo() {
  const { currentStaff } = useCurrentStaff(); 
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [basicData, setBasicData] = useState({});

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchBasicDetails({ 
      staffId: currentStaff.userId, 
      setData: setBasicData
    });

    return () => {
      unsubscribe();
    };
  }, [currentStaff.userId]);

  const formattedData = formatBasicData(basicData); // Adjusted for staff
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col span={4} style={{ textAlign: 'center' }}>
          <Image width="100%" src={basicData.imagePath} />
          <p style={{ marginBottom: '0' }}>Staff's ID Number:</p> // Text adjusted for staff
          <h3 style={{ margin: '0' }}>{basicData.StaffID}</h3> // Assuming staff ID field
        </Col>

        <Col span={20}>
          <Card
            type="inner"
            title="Basic Info"
            extra={<>
              <Button onClick={openDrawer}>Update</Button>
            </>}
            style={{ marginLeft: 10 }}
          >
            <Drawer title="Update Basic Info" width={500} onClose={closeDrawer} open={isDrawerOpen}>
              {/* <UpdateStaffInfoForm currentStaff={basicData} /> // Adjusted for staff */}
            </Drawer>

            <Descriptions layout="horizontal">
              {Object.entries(formattedData).map(([title, content]) => (
                <Descriptions.Item label={title}>{content}</Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StaffBasicInfo;