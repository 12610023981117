import React, { useEffect, useState } from 'react'
import { Card, Descriptions, Button,Drawer ,Popconfirm} from 'antd'
import { useCurrentStudent } from '../../../context/studentContext';
import { deleteAchivementDetails, fetchAchivementDetails, formatAchivementsData } from './store';
import AddReserchButton from './AddAchievmentButton';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function AchivementDetails() {
  const { currentStudent } = useCurrentStudent();
  const [achivementData, setAchivementData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const {userData} = useUserData();

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchAchivementDetails({
      studentId: currentStudent.userId,
      setData: setAchivementData
    });

    // Unsubscribe from real-time updates when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentStudent.userId]);

  const formattedData = formatAchivementsData(achivementData);
  const handleDelete = (id) => {
    deleteAchivementDetails({ id });
  };

  return (
    <div>
      {userData.role !== userTypes.STUDENT ? <AddReserchButton />:null}
      {formattedData.map((item) => (
        <div key={item.key} style={{ marginBottom: 10 }}>
          <Card
            type='inner'
            title={item.Topic}
            extra={<Popconfirm
              placement="left"
              title='remove?'
              description="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(item.key)}
            ><Button danger >Delete</Button></Popconfirm>}
            style={{ marginLeft: 10 }}
          >

            <Drawer title='Update Achivements Program' width={500} onClose={closeDrawer} open={isDrawerOpen}>

            </Drawer>
            <Descriptions layout='horizontal'>
              {Object.keys(item).filter(key => key !== 'key').map((key) => (
                <Descriptions.Item key={key} label={key}>
                  {isLink(item[key]) ? (
                    <a href={item[key]} target="_blank" rel="noopener noreferrer">
                      {item[key]}
                    </a>
                  ) : (
                    item[key]
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </div>
      ))}
    </div>
  )
}

function isLink(str) {
  const urlRegex = /^(http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}

export default AchivementDetails