// AppLayout.jsx

// Importing necessary modules and components
import Login from '../../pages/login/login';
import Signup from '../../pages/login/signup';
import React, { useState, useEffect, useContext } from 'react';
import { Layout, Spin } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Route, Routes, Navigate } from 'react-router-dom';
import './app_layout.css';
import AppSider from '../sider/sider';
import AppRoutes from '../../routes/routes';
import { firebaseAuth, firestore } from '../../services/firebase';
import { useUserData } from '../../context/userDataContext';
import { AuthContext } from '../../context/AuthContext';
import userTypes from '../../constants/userTypes';
import { useCurrentInstitution } from '../../context/currentInstitutionContext';
import collection from '../../constants/collection';

// Destructuring Layout components from antd
const { Header, Content } = Layout;

// Main AppLayout component
const AppLayout = ({ userType }) => {
  // State management for sidebar collapse and loading indicator
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  // Contexts for user and institution data
  const { userData, setUserData } = useUserData();
  const {setInstitutionData} = useCurrentInstitution();
  const { user, setUser } = useContext(AuthContext);

  // Function to toggle sidebar collapse
  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  // Effect hook to manage authentication state and fetch user/institution data
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async user => {
      setUser(user);
      if (user) {
        const userId = user.uid;
        // Fetching user data from Firestore
        const userRef = firestore.collection(collection.USERS_COLLECTION).doc(userId);
        const snapshot = await userRef.get();
        if (snapshot.exists) {
          setUserData(snapshot.data());
          
          // Fetching institution data if user is an institution head
          if (snapshot.data().role === userTypes.INSTITUTION_HEAD) {
            try {
              const instDoc = await firestore.collection(collection.INSTITUTIONS_COLLECTION).doc(snapshot.data().institution).get();
              setInstitutionData(instDoc.data());
            } catch (error) {
              console.error('Error fetching institution data:', error);
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });

    // Cleanup function to unsubscribe from auth state changes
    return () => unsubscribe();
  }, []);

  // Loading state UI
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  // Redirect to login if no user or userData is present
  if (!user || !userData) {
    return (
      <>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="*"
            element={<Navigate to="/login" replace />}
          />
        </Routes>
      </>
    );
  }

  // Main layout structure when user is logged in
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppSider collapsed={collapsed} userType={'admin'} />
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', zIndex: 1, width: '100%' }}>
          <MenuOutlined className="trigger" onClick={toggleMenu} />
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div style={{ marginRight: '10px',marginLeft: '10px', maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData.name}</div>
          </div>
        </Header>
        <Content className="site-layout-content">
          <AppRoutes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
