import React, { createContext, useContext, useState, useEffect } from 'react';

const AllCourseContext = createContext();

export const AllCourseProvider = ({ children }) => {
  const [allCourse, setAllCourse] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedCourse = localStorage.getItem('allCourse');
    return savedCourse ? JSON.parse(savedCourse) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allStudents changes
    localStorage.setItem('allCourse', JSON.stringify(allCourse));
  }, [allCourse]);

  return (
    <AllCourseContext.Provider value={{ allCourse, setAllCourse }}>
      {children}
    </AllCourseContext.Provider>
  );
};
export const useAllCourse = () => {
    return useContext(AllCourseContext);
  };
  