import collection from "../../constants/collection";
import { firestore } from "../../services/firebase";

export const stateList =[
    {"id": "AP", "name": "Andhra Pradesh"},
    {"id": "AR", "name": "Arunachal Pradesh"},
    {"id": "AS", "name": "Assam"},
    {"id": "BR", "name": "Bihar"},
    {"id": "CG", "name": "Chhattisgarh"},
    {"id": "GA", "name": "Goa"},
    {"id": "GJ", "name": "Gujarat"},
    {"id": "HR", "name": "Haryana"},
    {"id": "HP", "name": "Himachal Pradesh"},
    {"id": "JH", "name": "Jharkhand"},
    {"id": "KA", "name": "Karnataka"},
    {"id": "KL", "name": "Kerala"},
    {"id": "MP", "name": "Madhya Pradesh"},
    {"id": "MH", "name": "Maharashtra"},
    {"id": "MN", "name": "Manipur"},
    {"id": "ML", "name": "Meghalaya"},
    {"id": "MZ", "name": "Mizoram"},
    {"id": "NL", "name": "Nagaland"},
    {"id": "OD", "name": "Odisha"},
    {"id": "PB", "name": "Punjab"},
    {"id": "RJ", "name": "Rajasthan"},
    {"id": "SK", "name": "Sikkim"},
    {"id": "TN", "name": "Tamil Nadu"},
    {"id": "TS", "name": "Telangana"},
    {"id": "TR", "name": "Tripura"},
    {"id": "UP", "name": "Uttar Pradesh"},
    {"id": "UK", "name": "Uttarakhand"},
    {"id": "WB", "name": "West Bengal"},
    {"id": "AN", "name": "Andaman and Nicobar Islands"},
    {"id": "CH", "name": "Chandigarh"},
    {"id": "DN", "name": "Dadra and Nagar Haveli and Daman and Diu"},
    {"id": "DL", "name": "Delhi"},
    {"id": "JK", "name": "Jammu and Kashmir"},
    {"id": "LA", "name": "Ladakh"},
    {"id": "LD", "name": "Lakshadweep"},
    {"id": "PY", "name": "Puducherry"}
  ]
  export const fetchLanguages = async ({setData}) => {
    try {
      console.log('excecuted');
      let query;
      query = firestore.collection(collection.LANGUAGE_COLLECTION);
      const unsubscribe = query.onSnapshot(snapshot => {
        const languages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setData(languages);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  };
  export const fetchSkills = async ({setData}) => {
    try {
      console.log('fetching skills');
      let query;
      query = firestore.collection(collection.SKILL_COLLECTION);
      const unsubscribe = query.onSnapshot(snapshot => {
        const skills = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setData(skills);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  };
  export const formatSkillType = (type) => {
    return type
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};