
import collection from "../../../constants/collection";
import { firestore } from "../../../services/firebase";
import { formatDOB } from "../../../utils/formatDOB";
import { errorMessage, successMessage } from "../../../utils/messages";


export const fetchSulukDetails = ({ studentId, setData }) => {
  try {
    const query = firestore.collection(collection.SULUK_COLLECTION).where('student_id', '==', studentId);

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching academic details:', error);
  }
};
export const deleteSulukDetails = ({ id }) => {
  try {
   firestore.collection(collection.SULUK_COLLECTION).doc(id).delete();

    successMessage('Language removed')
  } catch (error) {
    errorMessage("Failed to remove Language")
    console.error('Error fetching suluk details:', error);
  }
};


export const formatSulukData = (data) => {

  return data.map(item => {
    const formattedItem = {
      "key":item.id,
      "Type":item.type,
      "Title":item.title,
      "Date":formatDOB(item.date),
      "Description":item.description,
      "Attachment":item.attachment,

    };
   

    return formattedItem;
  });
};

export const typeList = 
  [
    { "id": 1, "name": "Positive" }, 
    { "id": 2, "name": "Negative" },  
  ]