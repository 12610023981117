import React, { useState } from 'react';
import { Form, Select, Checkbox ,Button,Slider} from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import { addDataToFirestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { languageList } from './store';

function AddLanguageForm() {
    const [form] = Form.useForm();
    const [read, setRead] = useState(false); 
    const [listen, setListen] = useState(false); 
    const [write, setWrite] = useState(false); 
    const [speak, setSpeak] = useState(false); 
    const [native, setNative] = useState(false); 
    const { currentStudent } = useCurrentStudent();

    const handleReadChange = (e) => {
        setRead(e);
    };
    const handleListenChange = (e) => {
        setListen(e);
    };
    const handleWriteChange = (e) => {
        setWrite(e);
    };
    const handleSpeakChange = (e) => {
        setSpeak(e);
    };
    const handleNativeChange = (e) => {
        setNative(e.target.checked);
    };
    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            values['read'] = read;
            values['write'] = write;
            values['speak']=speak;
            values['native']= native;
            values['listen']= listen;
            values.student_id = currentStudent.userId;

            addDataToFirestore(collection.LANGUAGE_COLLECTION,values);
            form.resetFields();
            
            successMessage('Successfully Added');
            
        } catch (errorInfo) {
            errorMessage("Filed");
        }
    };

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                style={{ maxWidth: 600 }}
                initialValues={{}}
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[{ required: true, message: 'Please Select' }]}
                >
                    <Select showSearch placeholder="Select language">
                        {languageList.map((language) => (
                            <Select.Option key={language.id} value={language.name}>
                                {language.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Read">
                    <Slider onChange={handleReadChange}></Slider>
                </Form.Item>
                <Form.Item label="Listen">
                <Slider onChange={handleListenChange}></Slider>
                </Form.Item>
                <Form.Item label="Write">
                <Slider onChange={handleWriteChange}></Slider>
                </Form.Item>
                <Form.Item label="Speak">
                <Slider onChange={handleSpeakChange}></Slider>
                </Form.Item>
                <Form.Item label="Native">
                    <Checkbox checked={native} onChange={handleNativeChange} />
                </Form.Item>

                <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
            </Form>
        </div>
    );
}

export default AddLanguageForm;
