import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import moment from 'moment';
import { updateAttendanceRecord } from './store';
import { useUserData } from '../../context/userDataContext';
import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

const EditAttendanceForm = ({ record, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { userData } = useUserData();
  const [batches, setBatches] = useState([]);
  const institutionId = userData.role === 'institution_head' ? userData.institution : record.institution;

  useEffect(() => {
    const fetchBatches = async () => {
      const querySnapshot = await firestore.collection(collection.BATCHES_COLLECTION)
        .where('institution', '==', institutionId)
        .get();
      const batchList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBatches(batchList);
    };
    fetchBatches();
  }, [institutionId]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        date_time: moment(record.date_time, 'YYYY-MM-DD HH:mm:ss'),
        batches: record.batches,
      });
    }
  }, [record, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await updateAttendanceRecord(record.key, {
        name: values.name,
        date_time: values.date_time.format('YYYY-MM-DD HH:mm:ss'),
        institution: institutionId,
        batches: values.batches,
      });
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Error updating document:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please input the name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="date_time"
        label="Date and Time"
        rules={[{ required: true, message: 'Please select the date and time!' }]}
      >
        <DatePicker showTime format="HH:mm DD-MM-YYYY" />
      </Form.Item>
      <Form.Item
        name="batches"
        label="Batches"
        rules={[{ required: true, message: 'Please select the batches!' }]}
      >
        <Select mode="multiple">
          {batches.map((batch) => (
            <Select.Option key={batch.id} value={batch.id}>
              {batch.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditAttendanceForm;