import React, { useState, useEffect } from 'react';
import { Card, Descriptions, Button, Drawer,Popconfirm } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import AddCourse from './AddCourse';
import { deleteAcademicDetails, fetchAcademicDetails, formatData } from './store';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function AcademicDetails() {
  const { currentStudent } = useCurrentStudent();
  const [academicData, setAcademicData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const {userData} = useUserData();


  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (currentStudent.userId) {
      const unsubscribe = fetchAcademicDetails({
        studentId: currentStudent.userId,
        setData: setAcademicData
      });

      // Ensure unsubscribe is a function
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }
  }, [currentStudent.userId]);

  const handleDelete = async (documentId) => {
    try {
      await deleteAcademicDetails({ id: documentId });
      // Refresh the data after deletion
      setAcademicData((prevData) => prevData.filter((item) => item.key !== documentId));
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const formattedData = formatData(academicData);

  return (
    <div>
       {userData.role !== userTypes.STUDENT ?<AddCourse />:null}

      {formattedData.map((item) => (
        <div key={item.key} style={{ marginBottom: 10 }}>
          <Card
            type='inner'
            title={item.Name}
            
            extra={
              <Popconfirm
                placement="left"
                title="Remove?"
                description="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDelete(item.id)}
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            }
            style={{ marginLeft: 10 }}
          >
            <Drawer title='Update Academic Info' width={500} onClose={closeDrawer} open={isDrawerOpen}>

            </Drawer>
            <Descriptions layout='horizontal'>
                  {Object.keys(item).filter(key => key !== 'id').map((key) => (
                    <Descriptions.Item key={key} label={key}>{item[key]}</Descriptions.Item>
                  ))}
                </Descriptions>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default AcademicDetails;
