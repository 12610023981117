import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Checkbox } from 'antd';
import { addDataToFirestore } from '../../services/firebase';
import collection from '../../constants/collection';
import { firestore } from '../../services/firebase';
import { successMessage } from '../../utils/messages';

const AddInstitutionForm = () => {
  const [form] = Form.useForm();
  const [courseData, setCourseData] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedType, setSelectedType] = useState('academic'); // Default value for type
  const [isStaffAttendanceNeeded, setIsStaffAttendanceNeeded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await firestore.collection(collection.COURSES).get();
        const fetchedData = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setCourseData(fetchedData);
        const unsubscribe = firestore.collection(collection.COURSES).onSnapshot((snapshot) => {
          const updatedData = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          setCourseData(updatedData);
        });
        // The unsubscribe function will stop listening for updates when the component unmounts
        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleStaffAttendanceChange = (e) => {
    setIsStaffAttendanceNeeded(e.target.checked);
  };

  const handleSubmit = async () => {
    try {
      // Validate the form
      const values = await form.validateFields();

      // Log the values to check if checkbox values are properly captured
      values["courses"] = selectedCourses;
      values["staffAttendance"] = isStaffAttendanceNeeded;
      values["features"] = [1,2,3,4,5,6,7,8,9]

      // Add form data to Firestore
      addDataToFirestore(collection.INSTITUTIONS_COLLECTION, values);
      successMessage("Institution Added")
      form.resetFields();
    } catch (errorInfo) {
      console.error('Failed to submit:', errorInfo);
    }
  };
  

  const handleChange = (value) => {
    setSelectedCourses(value)
  };


  return (
    <Form
      layout='vertical'
      form={form}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input placeholder="Name of Institution" />
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: 'Please select the type' }]}
        initialValue={selectedType} // Set initial value
      >
        <Select onChange={handleTypeChange}>
          <Select.Option value="academic">Academic</Select.Option>
          <Select.Option value="non-academic">Non-Academic</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="staffAttendance"
        valuePropName="checked"
      >
        <Checkbox onChange={handleStaffAttendanceChange}>Staff Attendance Needed</Checkbox>
      </Form.Item>

      {selectedType === 'academic' && (
        <Form.Item
          label="Select Courses"
          name="courses"
          rules={[{ required: true }]}
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
          style={{ display: form.getFieldValue('type') === 'academic' ? 'block' : 'none' }}
        >
          <Select
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            onChange={handleChange}
          >
            {courseData.map((course) => (
              <Select.Option key={course.key} value={course.key}>
                {course.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddInstitutionForm;