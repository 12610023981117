
import { firestore } from "../../../services/firebase";
import collection from "../../../constants/collection";
import { message } from "antd";
import { successMessage,errorMessage } from "../../../utils/messages";
import { academicStatusList, streamList,religiousLevelList } from "../../../constants/constants";

export const fetchEducationDetails = ({ studentId, setData }) => {
  try {
    const query = firestore.collection(collection.EDUCATIONAL_COLLECTION).where('student_id', '==', studentId);

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching educational details:', error);
  }
};

export const deleteEducationDetails = async ({ id }) => {
  try {
    await firestore.collection(collection.EDUCATIONAL_COLLECTION).doc(id).delete();
    message.success('Education removed');
  } catch (error) {
    message.error('Failed to remove Education Details');
    throw error; // Re-throw the error to handle it in the component
  }
};

export const formatData = ({ data,courseList }) => {
  return data.map(item => {
    const course = courseList.find(e => e.id === item.course);
    const status = academicStatusList.find(e => e.id === item.status);
    const stream = streamList.find(e => e.id === item.stream);

    const formattedItem = {
      "id":item.id,
      "Name": course ? course.name : 'Unknown Course',
      "Enrolled Year": item.enrolled_year,
      "Status": status ? status.name : 'Unknown Status',
    };

    if (item.pass_year) {
      formattedItem["Passout Year"] = item.pass_year;
    }
    if (item.total_marks) {
      formattedItem["Percentage of Marks"] = item.total_marks;
    }

    return formattedItem;
  });
};