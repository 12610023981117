// AcademicChart.js

import React, { useEffect, useState } from 'react';
import {  BarChart, XAxis, YAxis, Legend, Bar, CartesianGrid, Tooltip, LabelList } from 'recharts';
import { Card } from 'antd';
import { useAllAcademic } from '../../../context/allAcademicContext';
import { useAllStudents } from '../../../context/allStudentContext';
import { fetchInstHeadStudents, fetchAcademics, levelListforChart } from './../store';

const AcademicCompletedChart = () => {
  const { allStudents } = useAllStudents();
  const { allAcademic, setAllAcademic } = useAllAcademic();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Fetch data when the component mounts
    const userData = { role: true }; // Replace with actual userData handling
    if (userData.role) {
      fetchAcademics({ setData: setAllAcademic });
    }
  }, [ setAllAcademic]);

  useEffect(() => {
    if (allStudents.length > 0 && allAcademic.length > 0) {
      // Extract student IDs whenever allStudents changes
      const studentIdList = allStudents.map(student => student.id);

      // Filter ongoing academic to include only those with student IDs in studentIdList and status === 1
      const ongoingStudentAcademics = allAcademic
        .filter(e => studentIdList.includes(e.student_id) && e.status === 2)
        .reduce((acc, curr) => {
          // Remove duplicates: keep the latest entry for each student_id within each level
          const key = `${curr.student_id}_${curr.level}`;
          acc[key] = curr;
          return acc;
        }, {});

      // Convert the reduced object back to an array
      const uniqueOngoingStudentAcademics = Object.values(ongoingStudentAcademics);

      // Find students not included in uniqueOngoingStudentAcademics
      const ongoingStudentIds = uniqueOngoingStudentAcademics.map(e => e.student_id);
      const notIncludedStudents = allStudents.filter(student => !ongoingStudentIds.includes(student.id));

      // Group the unique ongoing academic records by level
      const academicsOnLevel = uniqueOngoingStudentAcademics.reduce((acc, curr) => {
        const level = curr.level;
        if (!acc[level]) {
          acc[level] = [];
        }
        acc[level].push(curr);
        return acc;
      }, {});

      // Convert the grouped academic records into an array format suitable for recharts
      const ongoingChartData = Object.keys(academicsOnLevel).map(level => {
        const levelInfo = levelListforChart.find(e => e.id === parseInt(level));
        return {
          name: levelInfo ? levelInfo.name : `Level ${level}`,
          completed: academicsOnLevel[level].length,
        };
      });

      // Add not included students count to the chart data
      ongoingChartData.push({
        name: 'None',
        completed: notIncludedStudents.length,
      });

      setChartData(ongoingChartData);
    }
  }, [allStudents, allAcademic]);

  return (
    <Card bordered={false} title='Academic Status' style={{ width: '100%', height: '100%' }}>
      <BarChart width={300} height={200} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="completed" fill="#375b9e">
          <LabelList dataKey="completed" position="top" />
        </Bar>
      </BarChart>
    </Card>
  );
};

export default AcademicCompletedChart;