const collection = {
    INSTITUTIONS_COLLECTION: 'institutions',
    STUDENTS_COLLECTION:'students',
    STUDENTS_IMAGES_COLLECTION:'studentImages',
    USERS_COLLECTION:'users',
    EDUCATIONAL_COLLECTION:'educational',
    ACADEMIC_COLLECTION:'academic',
    LANGUAGE_COLLECTION:'language',
    SULUK_COLLECTION:'suluk',
    SKILL_COLLECTION:'skills',
    ACHIEVEMENTS_COLLECTION:'achivements',
    FAMILY_COLLECTION :'family',
    ADMISSION_COLLECTION:'admission',
    COURSES:'courses',
    BATCHES_COLLECTION:'batches',
    ATTENDANCE_LIST_COLLECTION:'attendanceList',
    ABSENTEES_COLLECTION:'absentees',
    STAFF_COLLECTION:'staff',
    CONTRIBUTIONS_COLLECTION:'contributions'

}

export default collection;