import React, { useState, useEffect } from 'react';
import { Card, Descriptions, Button, Drawer } from 'antd';
import { useCurrentStudent } from '../../../context/studentContext';
import UpdateAdmissionForm from './UpdateAdmissionForm';
import { firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { statusList, typeList } from '../../../constants/constants';
import { formatDOB } from '../../../utils/formatDOB';
import { fetchAdmissionDetails } from './store';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";

function AdmissionDetails() {
  const { currentStudent, setStudentData } = useCurrentStudent();
  const [institutionList, setInstitutionList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [admissionData, setAdmissionData] = useState({});
  const { userData } = useUserData();



  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchAdmissionDetails({
      studentId: currentStudent.userId,
      setData: setAdmissionData
    });

    // Unsubscribe from real-time updates when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentStudent.userId]);

  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        const institutionSnapshot = await firestore.collection(collection.INSTITUTIONS_COLLECTION).get();
        const fetchedInstitutions = institutionSnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setInstitutionList(fetchedInstitutions);
      } catch (error) {
        console.error('Error fetching institutions:', error);
      }
    };

    fetchInstitutions();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      if (currentStudent?.institution) {
        try {
          const institution = institutionList.find(inst => inst.key === currentStudent.institution);
          if (institution && institution.courses) {
            const coursePromises = institution.courses.map(courseId =>
              firestore.collection(collection.COURSES).doc(courseId).get()
            );
            const courseSnapshots = await Promise.all(coursePromises);
            const fetchedCourses = courseSnapshots.map(doc => ({
              key: doc.id,
              ...doc.data(),
            }));
            setCourseList(fetchedCourses);
          }
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };
    const fetchBatches = async () => {
      try {
        const coursesSnapshot = await firestore.collection(collection.BATCHES_COLLECTION)
          .get();
        const fetchedBatches = coursesSnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setBatchList(fetchedBatches);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchBatches()
    fetchCourses();
  }, [currentStudent?.institution, institutionList]);

  const filteredInstitution = institutionList.find(e => e.key === admissionData?.institution);
  const previousInstitution = institutionList.find(e => e.key === admissionData?.previous_institution);
  const filteredCourse = courseList.find(e => e.key === admissionData?.admitted_course);
  const filteredStatus = statusList.find(e => e.id === admissionData?.status);
  const filteredType = typeList.find(e => e.id === admissionData?.type);
  const filteredBatch = batchList.find(e => e.id === admissionData?.batch);


  const items = [
    {
      key: '1',
      label: 'Institution',
      children: filteredInstitution ? filteredInstitution.name : '',
    },
    {
      key: '8',
      label: 'Batch',
      children: filteredBatch ? filteredBatch.name : '',
    },
    {
      key: '9',
      label: 'Admission Number',
      children: admissionData.admission_number ? admissionData.admission_number : '',
    },
    {
      key: '2',
      label: 'Admission Date',
      children: formatDOB(admissionData?.admission_date),
    },
    {
      key: '3',
      label: 'Admitted Course',
      children: filteredCourse ? filteredCourse.name : '',
    },
    {
      key: '10',
      label: 'Admitted Year',
      children: admissionData.admitted_course_year
    },
    {
      key: '4',
      label: 'Admission Type',
      children: filteredType ? filteredType.name : '',
    },
    {
      key: '5',
      label: 'Status',
      children: filteredStatus ? filteredStatus.name : '',
    },
    {
      key: '6',
      label: 'Previous Institution',
      children: admissionData?.previous_institution === 0 ? admissionData.other_inst_name : previousInstitution ? previousInstitution.name : '',
    },
  ];

  return (
    <div>
      <Card type="inner" title="Admission Details"
        extra={userData.role !== userTypes.STUDENT ? <Button onClick={openDrawer}>Update</Button> : null}

        style={{ marginLeft: 10 }}
      >
        <Drawer title="Update Admission Info" width={500} onClose={closeDrawer} open={isDrawerOpen}>
          <UpdateAdmissionForm currentStudent={currentStudent} />
        </Drawer>
        <Descriptions layout="horizontal" items={items} />
      </Card>
    </div>
  );
}

export default AdmissionDetails;
