import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Checkbox,message } from 'antd';
import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';

function UpdateInstitutionForm({ institution }) {
  const [form] = Form.useForm();
  const [courseData, setCourseData] = useState([]);
  const [institutionType, setInstitutionType] = useState('');

  useEffect(() => {
    // Fetch courses for the select options
    const unsubscribe = firestore.collection(collection.COURSES).onSnapshot(snapshot => {
      const fetchedCourses = snapshot.docs.map(doc => ({
        key: doc.id,
        name: doc.data().name,
      }));
      setCourseData(fetchedCourses);
    });

    // Set initial form values and institution type
    if (institution) {
      form.setFieldsValue({
        name: institution.name,
        type: institution.type,
        staffAttendance: institution.staffAttendance,
        courses: institution.courses,
      });
      setInstitutionType(institution.type);
    }

    return () => unsubscribe();
  }, [institution, form]);

  const handleSubmit = async (values) => {
    try {
      // Assuming `institution` contains an `id` field to identify the document
      const docRef = firestore.collection(collection.INSTITUTIONS_COLLECTION).doc(institution.id);
      await docRef.update({
        ...values, // Spread the form values to update
      });
      message.success('Institution updated successfully');
      // You might want to navigate the user away from the form or refresh the form/page
    } catch (error) {
      console.error('Update failed:', error);
      message.error('Update failed. Please try again.');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={{ maxWidth: 600 }}
      onValuesChange={(changedValues, allValues) => {
        // Check if the type field is changed
        if (changedValues.type) {
          setInstitutionType(changedValues.type);
        }
      }}
    >
      {/* Name field */}
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter the institution name!' }]}
      >
        <Input />
      </Form.Item>

      {/* Type field */}
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: 'Please select the type!' }]}
      >
        <Select>
          <Select.Option value="academic">Academic</Select.Option>
          <Select.Option value="non-academic">Non-Academic</Select.Option>
        </Select>
      </Form.Item>

      {/* Staff Attendance field */}
      <Form.Item
        name="staffAttendance"
        valuePropName="checked"
      >
        <Checkbox>Staff Attendance Needed</Checkbox>
      </Form.Item>

      {/* Courses field, shown only for academic institutions */}
      {institutionType === 'academic' && (
        <Form.Item
          name="courses"
          label="Select Courses"
          rules={[{ required: true, message: 'Please select at least one course!' }]}
        >
          <Select
            mode="multiple"
            placeholder="Please select"
          >
            {courseData.map(course => (
              <Select.Option key={course.key} value={course.key}>
                {course.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Update Institution
        </Button>
      </Form.Item>
    </Form>
  );
}

export default UpdateInstitutionForm;