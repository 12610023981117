import React, { createContext, useContext, useState, useEffect } from 'react';

const AllAcademicContext = createContext();

export const AllAcademicProvider = ({ children }) => {
  const [allAcademic, setAllAcademic] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedAcademic = localStorage.getItem('allAcademic');
    return savedAcademic ? JSON.parse(savedAcademic) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allStudents changes
    localStorage.setItem('allAcademic', JSON.stringify(allAcademic));
  }, [allAcademic]);

  return (
    <AllAcademicContext.Provider value={{ allAcademic, setAllAcademic }}>
      {children}
    </AllAcademicContext.Provider>
  );
};
export const useAllAcademic = () => {
    return useContext(AllAcademicContext);
  };
  