// InstHeadDashBoard.js

import React, { useEffect } from 'react';
import { useUserData } from '../../context/userDataContext';
import { Row, Col, Card, Statistic } from 'antd';
import AcademicOngoingChart from './components/AcademicOngoingChart';
import AcademicCompletedChart from './components/AcademicCompletedChart';
import { useAllStudents } from '../../context/allStudentContext';
import { fetchInstHeadStudents } from './store';
import ReligiousOngoingChart from './components/ReligiousOngoingChart';

function InstHeadDashBoard() {
  const { allStudents, setAllStudents } = useAllStudents();
  const { userData } = useUserData();

  useEffect(() => {
    if (userData.role) {
      fetchInstHeadStudents({ setData: setAllStudents, instId: userData.institution });
    }
  }, [userData, setAllStudents]);

  const verifiedStudents = allStudents.filter(e => e.adhaar !== undefined && e.status !== undefined);
  const familyVerifiedStudents = allStudents.filter(e => e.adhaar !== undefined && e.status !== undefined && e.father !== undefined);
  const notVerifiedStudents = allStudents.filter(e => e.adhaar === undefined || e.status === undefined);

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false} style={{ marginBottom: 10 }}>
            <Statistic
              title="Students"
              value={allStudents.length}
              valueStyle={{ color: '#9307f0' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false} style={{ marginBottom: 10 }}>
            <Statistic
              title="Verified Students"
              value={verifiedStudents.length}
              valueStyle={{ color: '#0741f0' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false} style={{ marginBottom: 10 }}>
            <Statistic
              title="Family Details Updated"
              value={familyVerifiedStudents.length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false} style={{ marginBottom: 10 }}>
            <Statistic
              title="Not Verified"
              value={notVerifiedStudents.length}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col xs={24} xl={12} md={12} style={{ marginBottom: 16 }}>
          <AcademicOngoingChart />
        </Col>
        <Col xs={24} xl={12} md={12} style={{ marginBottom: 16 }}>
          <AcademicCompletedChart />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col xs={24} xl={12} style={{ marginBottom: 16 }}>
          <ReligiousOngoingChart />
        </Col>
      </Row>
    </>
  );
}

export default InstHeadDashBoard;