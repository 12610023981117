export const formatDOB = (dob) => {
    try {
        if (dob instanceof Date) {
            // If dob is already a Date object, use it directly
            return dob.toLocaleDateString();
        } else if (dob && dob.seconds) {
            // If dob is an object with seconds property, construct a Date
            const date = new Date(dob.seconds * 1000); // Convert seconds to milliseconds
            return date.toLocaleDateString();
        } else {
            // Handle other cases or log an error
            console.error('Invalid Date of Birth format:', dob);
            return null;
        }
    } catch (error) {
        console.error('Error formatting Date of Birth:', error);
        return null;
    }
};

