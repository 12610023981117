import { message } from 'antd';

export const infoMessage = (text) => {
  message.info(text);
};

export const successMessage = (text) => {
    message.success(text);
};
export const errorMessage = (text) => {
    message.error(text);
};