import React from 'react';
import { Form, Select, Button, Input, Upload, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useCurrentStudent } from '../../../context/studentContext';
import { addDataToFirestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { typeList } from './store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore'; // Import Firestore explicitly for Timestamp

const firestore = firebase.firestore();

function AddSulukForm() {
    const [form] = Form.useForm();
    const { currentStudent } = useCurrentStudent();
    const storage = firebase.storage();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            values.student_id = currentStudent.userId;

            // Handle file upload to Firebase Storage
            if (values.attachment && values.attachment.length > 0) {
                const attachmentFile = values.attachment[0].originFileObj;
                const storageRef = storage.ref();

                // Generate a unique filename based on current timestamp
                const timestamp = Date.now();
                const filename = `${timestamp}_${attachmentFile.name}`;
                const fileRef = storageRef.child(`attachments/${filename}`);
                
                await fileRef.put(attachmentFile);
                const downloadURL = await fileRef.getDownloadURL();
                values.attachment = downloadURL;
            }

            values.date = values.date.toDate()

            // Save form data (including attachment URL) to Firestore
            await addDataToFirestore(collection.SULUK_COLLECTION, values);
            form.resetFields();
            successMessage('Successfully Added');
        } catch (errorInfo) {
            errorMessage("Failed");
        }
    };

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                style={{ maxWidth: 600 }}
                initialValues={{}}
            >
                <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true, message: 'Please Select' }]}
                >
                    <Select showSearch placeholder="Select type">
                        {typeList.map((type) => (
                            <Select.Option key={type.id} value={type.name}>
                                {type.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please enter title' }]}
                >
                    <Input placeholder="Enter title" />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please enter description' }]}
                >
                    <Input.TextArea placeholder="Enter description" />
                </Form.Item>

                <Form.Item
                    label="Date"
                    name="date"
                    rules={[{ required: true, message: 'Please select date' }]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    label="Attachment"
                    name="attachment"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Upload
                        name="attachment"
                        listType="picture"
                        beforeUpload={() => false}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>

                

                <Form.Item>
                    <Button type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AddSulukForm;
