import StaffBasicInfo from "./basic/StaffBasicInfo";

const    categories =[
    {
        key: '1',
        label: 'Basic Info',
        children: StaffBasicInfo,
      
    },
]

export default categories;