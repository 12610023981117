import React, { useState, useEffect } from 'react';
import { Card, Drawer, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCurrentStaff } from '../../context/staffContext';
import { useInstitution } from '../../context/InstitutionContext';
import AppButton from '../../components/app_button';
import AppTable from '../../components/app_table';
import '../../assets/styles.css';
import AddStaffForm from './AddStaffForm';
import { fetchStaffs, formatData } from './store';
import { useUserData } from '../../context/userDataContext';
import userTypes from '../../constants/userTypes';

function Staffs() {
  const [tableData, setTableData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { setStaffData } = useCurrentStaff();
  const navigate = useNavigate();
  const { institutionList } = useInstitution();
  const { userData } = useUserData();

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Institution',
      dataIndex: 'institution',
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   render: (_, record) => (
    //     <>
    //       <Button type='link' onClick={() => handleViewClick(record)}>View</Button>
    //     </>
    //   ),
    // },
  ];

  const handleViewClick = (record) => {
    console.log(record);
    setStaffData(record);
    navigate(`/staff-details`);
  };

  useEffect(() => {
    const fetchData = async () => {
      let filter = {};
      console.log(userData.institution);
      if (userData.role === userTypes.INSTITUTION_HEAD) {
        filter = { institution: userData.institution };
      }
      const unsubscribe = fetchStaffs({ setData: setTableData, filter });

      // Cleanup subscription on unmount
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    };
    fetchData();
  }, [userData.role, userData.institution]);

  const formattedData = formatData(tableData, institutionList); // Assuming formatData is a function you have for formatting your data

  return (
    <div>
      <Card
        title="Staffs"
        extra={<AppButton text='Add Staff' onClick={openDrawer} />}
      >
        <Drawer title='Add Staff' width={500} onClose={closeDrawer} open={isDrawerOpen}>
          <AddStaffForm />
        </Drawer>
        <AppTable columns={columns} data={formattedData} />
      </Card>
    </div>
  );
}

export default Staffs;