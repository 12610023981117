import React, { createContext, useContext, useState, useEffect } from 'react';

const AllStudentsContext = createContext();

export const AllStudentsProvider = ({ children }) => {
  const [allStudents, setAllStudents] = useState(() => {
    // Initialize state with data from local storage, if available
    const savedStudents = localStorage.getItem('allStudents');
    return savedStudents ? JSON.parse(savedStudents) : [];
  });

  useEffect(() => {
    // Save data to local storage whenever allStudents changes
    localStorage.setItem('allStudents', JSON.stringify(allStudents));
  }, [allStudents]);

  return (
    <AllStudentsContext.Provider value={{ allStudents, setAllStudents }}>
      {children}
    </AllStudentsContext.Provider>
  );
};

export const useAllStudents = () => {
  return useContext(AllStudentsContext);
};
