import React, { createContext, useContext, useState, useEffect } from 'react';


const AllStaffContext = createContext();

export const AllStaffProvider = ({ children }) => {
  const [allStaff, setAllStaff] = useState(() => {
    const savedStaff = localStorage.getItem('allStaff');
    return savedStaff ? JSON.parse(savedStaff) : [];
  });

  useEffect(() => {
    localStorage.setItem('allStaff', JSON.stringify(allStaff));
  }, [allStaff]);

  return (
    <AllStaffContext.Provider value={{ allStaff, setAllStaff }}>
      {children}
    </AllStaffContext.Provider>
  );
};

export const useAllStaff = () => {
  return useContext(AllStaffContext);
};