import { firestore } from "../../../services/firebase";
import { useCurrentStudent } from "../../../context/studentContext";
import collection from "../../../constants/collection";
import { message } from "antd";
import { boardList, collegeList, levelList, schoolList, academicStatusList, streamList, universityList, hssStreamList } from "../../../constants/constants";


export const fetchAcademicDetails = ({ studentId, setData }) => {
  try {
      const query = firestore.collection(collection.ACADEMIC_COLLECTION).where('student_id', '==', studentId);

      // Subscribe to real-time updates
      const unsubscribe = query.onSnapshot((snapshot) => {
          const fetchedData = snapshot.docs.map((doc) => ({
              key: doc.id,
              ...doc.data(),
          }));
          setData(fetchedData); // Update state with fetched data
      });

      // Return unsubscribe function
      return unsubscribe;
  } catch (error) {
      console.error('Error fetching academic details:', error);
  }
};

export const formatData = (data) => {
  return data.map(item => {
    const formattedItem = {
      "id":item.id,
      "Name": item.course_name ?? levelList.find((e)=>e.id === item.level).name,
      // "Level":levelList.find((e)=>e.id === item.level).name,
      "Enrolled Year": item.enrolled_year,
      "Status":academicStatusList.find((e)=>e.id === item.status).name,
      "Study Mode" : streamList.find((e)=>e.id === item.stream).name,
      
    };
    if(item.register_number){
      formattedItem["Register Number"] = item.register_number;
    }
    if(item.hss_stream){
      formattedItem["Stream"] = hssStreamList.find((e)=>e.id === item.hss_stream).name;
    }
    if(item.no_of_terms){
      formattedItem["Number Of Terms"] = item.no_of_terms;
    }
    if (item.board) {
      formattedItem["Board"] = boardList.find((e)=>e.id === item.board).name;
    }
    if (item.university) {
      formattedItem["University"] = universityList.find((e)=>e.id === item.university).name;
    }
    if (item.college) {
      formattedItem["College"] = collegeList.find((e)=>e.id === item.college).name;
    }
    if (item.school) {
      formattedItem["School"] = schoolList.find((e)=>e.id === item.school).name;
    }
    if (item.pass_year) {
      formattedItem["Passout Year"] = item.pass_year;
    }
    if (item.total_marks) {
      formattedItem["Percentage of Marks"] = item.total_marks;
    }

    return formattedItem;
  });
};

export const deleteAcademicDetails = async ({ id }) => {
  try {
    await firestore.collection(collection.ACADEMIC_COLLECTION).doc(id).delete();
    message.success('Academic Data removed');
  } catch (error) {
    message.error('Failed to remove Academic Details');
    throw error; // Re-throw the error to handle it in the component
  }
};
