import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Select, DatePicker, Col, Row } from 'antd';
import { addDataToFirestore, firestore } from '../../services/firebase';
import { errorMessage, successMessage } from '../../utils/messages';
import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import axios from 'axios'
import urls from '../../constants/urls';
import collection from '../../constants/collection';
import { AuthContext } from '../../context/AuthContext';
import { useUserData } from '../../context/userDataContext';

const storage = firebase.storage();

const AddStudentForm = () => {
    const [form] = Form.useForm();
    const [institutionList, setInstitutionList] = useState([]);
    const [token, setToken] = useState()
    const { user } = useContext(AuthContext)
    const { userData, setUserData } = useUserData();



    // getting institution list to display on dropsdown 
    useEffect(() => {
        getToken()
        const fetchData = async () => {
            try {
                const querySnapshot = await firestore.collection(collection.INSTITUTIONS_COLLECTION).get();
                const fetchedData = querySnapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));
                setInstitutionList(fetchedData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    //end getting institution list from firebase

    // Get Token
    const getToken = () => {
        user.getIdToken().then((token) => {
            setToken(token)
        })
            .catch(function (error) {

            });
    }
    //  ---- End Get Token


   

    const handleSubmit = async () => {
        try {
            // Validate the form
            const values = await form.validateFields();
            const data = {
                ...values,
                flag: true,
                active: true
            };
            // Convert dob field to JavaScript Date object
            if (values.enrolled_date) {
                values.enrolled_date = values.enrolled_date.toDate(); // Convert Moment object to Date object
            }
            if (values.dob) {
                values.dob = values.dob.toDate(); // Convert Moment object to Date object
            }

            const response = await axios.post(
                urls.BASE_URL + urls.ADD_STUDENT,
                {
                    "name": values.name,
                    "email": values.email,
                    "enrolled_date": values.enrolled_date,
                    "institution": values.institution,
                    "dob": values.dob,
                    "role": 'student',
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    }
                });
            // const docRef = await addDataToFirestore(collection.STUDENTS_COLLECTION, data);

            successMessage('Student Added');
            form.resetFields();

        } catch (errorInfo) {
            errorMessage('Failed to Add Student');
            console.error('Failed to submit:', errorInfo);
        }
    };



    return (
        <Form
            layout="vertical"
            form={form}
            style={{
                maxWidth: 600,
            }}
            initialValues={{'institution':userData.institution}}
        >

            
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter the name' }]}
            >
                <Input placeholder="Name of Student" />
            </Form.Item>


            <Form.Item
                label="E-mail"
                name="email"
                rules={[
                    { required: true, message: 'Please enter an email!' },
                    { type: 'email', message: 'Please enter a valid email address!' },
                ]}
            >
                <Input
                    placeholder="Email"
                />
            </Form.Item>
            <Row gutter={12}>
                <Col span={12}>

                    <Form.Item
                        label="Enrolled Date"
                        name="enrolled_date"
                        rules={[{ required: true, message: 'Please select a date' }]}
                    >
                        <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Date of birth"
                        name="dob"
                        rules={[{ required: true, message: 'Please select a date' }]}
                    >
                        <DatePicker  style={{ width: '100%' }} format="DD-MM-YYYY"/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                label="Institution"
                name="institution"
                rules={[
                    {
                        required: true,
                        message: 'Please select institution',
                    },
                ]}
            >
                {/* Dropdown menu - Institution */}
                <Select disabled>
                    {institutionList.map(institution => (
                        <Select.Option key={institution.key} value={institution.key}>
                            {institution.name}
                        </Select.Option>
                    ))}
                </Select>
                {/* Dropdown menu ends - Institution */}
            </Form.Item>





            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddStudentForm;
