import React, { useState, useEffect } from 'react';
import { Card, Button, Drawer, List, Tooltip, Popconfirm, Alert, Tabs } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone, EyeTwoTone } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchAttendanceData, deleteAttendanceRecord } from './store';
import AddAttendanceForm from './AddAttendanceForm';
import EditAttendanceForm from './EditAttendanceForm';
import { useInstitution } from '../../context/InstitutionContext';
import { useUserData } from '../../context/userDataContext';
import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';
import moment from 'moment';

const { TabPane } = Tabs;

function AttendanceList() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [activeBatch, setActiveBatch] = useState(null);
  const { institutionList } = useInstitution();
  const { userData } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const institutionId = userData.role === 'institution_head' ? userData.institution : location.state.instId;

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);
  const openEditDrawer = (record) => {
    setSelectedRecord(record);
    setEditDrawerOpen(true);
  };
  const closeEditDrawer = () => setEditDrawerOpen(false);

  const handleDelete = async (key) => {
    try {
      await deleteAttendanceRecord(key);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleView = (record) => {
    navigate('/attendance_details', { state: { record } });
  };

  useEffect(() => {
    const fetchBatches = async () => {
      const querySnapshot = await firestore.collection(collection.BATCHES_COLLECTION)
        .where('institution', '==', institutionId)
        .get();
      const batchList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      batchList.sort((a, b) => a.name.localeCompare(b.name)); // Sort batches alphabetically by name
      setBatches(batchList);
      if (batchList.length > 0) {
        setActiveBatch(batchList[0].id);
      }
    };
    fetchBatches();
  }, [institutionId]);

  useEffect(() => {
    const unsubscribe = fetchAttendanceData((data) => {
      const filteredData = data.filter(record => record.institution === institutionId);
      setAttendanceData(filteredData);
    });
    return () => unsubscribe();
  }, [institutionId]);

  const getBatchName = (id) => {
    const batch = batches.find((batch) => batch.id === id);
    return batch ? batch.name : 'Unknown';
  };

  const groupedData = attendanceData.reduce((acc, record) => {
    record.batches.forEach(batch => {
      if (!acc[batch]) {
        acc[batch] = [];
      }
      acc[batch].push(record);
    });
    return acc;
  }, {});

  return (
    <div>
      <Card
        title="Attendance List"
        extra={
          <Button type="primary" onClick={openDrawer}>
            <PlusOutlined /> Add Attendance
          </Button>
        }
      >
        <Alert message="This feature is under development" type="warning" showIcon style={{ marginBottom: 16 }} />
        <Tabs activeKey={activeBatch} onChange={setActiveBatch}>
          {batches.map(batch => (
            <TabPane tab={batch.name} key={batch.id}>
              <List
                itemLayout="horizontal"
                dataSource={groupedData[batch.id] || []}
                renderItem={record => (
                  <List.Item
                    actions={[
                      <Tooltip title="View">
                        <EyeTwoTone onClick={() => handleView(record)} style={{ color: 'green', cursor: 'pointer' }} />
                      </Tooltip>,
                      <Tooltip title="Edit">
                        <EditTwoTone onClick={() => openEditDrawer(record)} style={{ color: 'blue', cursor: 'pointer', marginLeft: 12 }} />
                      </Tooltip>,
                      <Popconfirm
                        title="Are you sure to delete this record?"
                        onConfirm={() => handleDelete(record.key)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title="Delete">
                          <DeleteTwoTone style={{ color: 'red', cursor: 'pointer', marginLeft: 12 }} />
                        </Tooltip>
                      </Popconfirm>
                    ]}
                  >
                    <List.Item.Meta
                      title={record.name}
                      description={`${moment(record.date, 'YYYY-MM-DD').format('DD-MM-YYYY')} ${moment(record.time, 'HH:mm:ss').format('hh:mm A')}`}
                    />
                  </List.Item>
                )}
              />
            </TabPane>
          ))}
        </Tabs>
      </Card>
      <Drawer title="Add Attendance" width={500} onClose={closeDrawer} open={isDrawerOpen}>
        <AddAttendanceForm onClose={closeDrawer} activeBatch={activeBatch} />
      </Drawer>
      <Drawer title="Edit Attendance" width={500} onClose={closeEditDrawer} open={isEditDrawerOpen}>
        <EditAttendanceForm record={selectedRecord} onClose={closeEditDrawer} />
      </Drawer>
    </div>
  );
}

export default AttendanceList;