import React, { useState, useEffect } from 'react';
import { Button, Form, Input, DatePicker } from 'antd';
import { addDataToFirestore } from '../../services/firebase';
import collection from '../../constants/collection';
import { firestore } from '../../services/firebase';
import { successMessage } from '../../utils/messages';
import { useUserData } from '../../context/userDataContext';

function AddBatchForm() {
    const [form] = Form.useForm();
    const { userData, setUserData } = useUserData();


    const handleSubmit = async () => {
      try {
        // Validate the form
        const values = await form.validateFields();
  
        // Log the values to check if checkbox values are properly captured
        values.start_year = values.start_year.year();
        values.institution = userData.institution;
        // Add form data to Firestore
        addDataToFirestore(collection.BATCHES_COLLECTION, values);
        successMessage("Batch Added")
        form.resetFields();
      } catch (errorInfo) {
        console.error('Failed to submit:', errorInfo);
      }
    };
    

  
    return (
      <Form
        layout='vertical'
        form={form}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter the name' }]}
        >
          <Input placeholder="Name of Batch" />
        </Form.Item>

        <Form.Item
          label="Start Year"
          name="start_year"
          rules={[{ required: true, message: 'Please Select an year' }]}
        >
          <DatePicker picker='year' />
        </Form.Item>
  
        
        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
}

export default AddBatchForm